/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { setGoBackTo, setNavigationPage } from 'redux/userProfileSlice';
import { useDispatch } from 'react-redux';
import {
  Grid, Tabs, Tab, AppBar, Box, Stack,
  Tooltip,
  Button,
} from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Instances from 'modules/AdminTestLibraries/TestLibraryDetail/Instances';
import Layout from 'components/Layout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { IInstances, IQuestion, ITestLibraries } from 'redux/interfaces';
import TestLibraryForm from 'modules/AdminTestLibraries/TestLibraryDetail/TestLibraryForm';
import InstanceNew from 'modules/AdminTestLibraries/TestLibraryDetail/InstanceNew';
import QuestionList from 'modules/AdminTestLibraries/Questions/List';
import QuestionsNew from 'modules/AdminTestLibraries/TestLibraryDetail/QuestionsNew';
import useInternalStorage from 'hooks/useInternalStorage';

interface IFormValue {
  id: string, value: any
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function AdminTestLibraryDetail() {
  const { testLibraryId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [questionList] = useInternalStorage<any[]>(String(testLibraryId), []);
  const [testLibrariesList, addTestLibrary] = useInternalStorage<any[]>('testLibraryList', []);
  const [value, setValue] = useState<number>(0);
  const [questions, setQuestions] = useState<IQuestion[]>(questionList);
  const [instances, setInstances] = useState<IInstances[]>([]);
  const [openModalInstance, setOpenModalInstance] = useState<boolean>(false);
  const [openModalQuestion, setOpenModalQuestion] = useState<boolean>(false);

  const test: ITestLibraries = testLibrariesList?.find((t: ITestLibraries) => t.id === Number(testLibraryId))
  const form = Object.entries(test).map(([id, value]) => ({ id, value })) || [];

  const [formValue, setFormValue] = useState<IFormValue[]>(form);

  useEffect(() => {
    dispatch(setNavigationPage('New Test Library'));
    dispatch(setGoBackTo(location.pathname));
  }, []);

  if (!testLibraryId) return <> </>;

  const handleFormResponse = (obj: { id: string, value: any }) => {
    setFormValue((_values) => {
      const existingIndex = _values?.findIndex((item) => item.id === obj.id);
      return existingIndex !== -1
        ? _values?.map((item, index) => (index === existingIndex ? obj : item))
        : [..._values, obj];
    })
  };

  const handleSave = () => {
    const testLibrary = formValue?.reduce((result, item) => {
      result[item.id] = item.value;
      return result;
    }, {}) as ITestLibraries

    addTestLibrary(testLibrary?.id, testLibrary)
    enqueueSnackbar('Test Edited Successfully', { variant: 'success' });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Grid container spacing={1}>
        <Stack
          p={1}
          flexDirection="row"
          justifyContent="flex-end"
          gap={1}
          bgcolor={(theme) => theme.palette.background.paper}
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
            height: 'auto',
            width: '100%',
            marginBottom: 1,
          }}
        >
          <Tooltip title=" Save new instance">
            <Button variant="contained" onClick={() => setOpenModalInstance(true)}>New Instance</Button>
          </Tooltip>
          <Tooltip title=" Save new question">
            <Button variant="contained" onClick={() => setOpenModalQuestion(true)}>Add Question</Button>
          </Tooltip>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
            <Button variant="contained" onClick={handleSave}>{BUTTON_TEXT.SAVE}</Button>
          </Tooltip>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="text" onClick={() => navigate('/admin/test-libraries')}>{BUTTON_TEXT.BACK}</Button>
          </Tooltip>
        </Stack>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <AppBar position="static" sx={{ bgcolor: 'background.paper' }}>
            <Tabs value={value} onChange={handleChange} variant="fullWidth">
              <Tab
                icon={<PersonOutlineIcon />}
                iconPosition="start"
                label="Test Library"
              />
              <Tab
                icon={<GroupsIcon />}
                iconPosition="start"
                label="Instances"
              />
              <Tab
                icon={<HowToRegIcon />}
                iconPosition="start"
                label="Questions"
              />
            </Tabs>
          </AppBar>
          <CustomTabPanel value={value} index={0}>
            <TestLibraryForm
              onCallback={(obj: IFormValue) => handleFormResponse(obj)}
              testLib={test}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Instances
              instances={instances}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <QuestionList questions={questions} parent={`#${testLibraryId}`} />
          </CustomTabPanel>
        </Box>
      </Grid>
      <InstanceNew
        openModal={openModalInstance}
        setOpenModal={() => setOpenModalInstance((_open) => !_open)}
        onCallback={(obj: IInstances[]) => {
          setInstances((_inst) => (_inst.concat(obj)))
          setOpenModalInstance((_open) => !_open)
        }}
      />
      <QuestionsNew
        openModal={openModalQuestion}
        setOpenModal={() => {
          setOpenModalQuestion((_open) => !_open);
          const storedData = localStorage.getItem(testLibraryId);
          const questionList = storedData ? JSON.parse(storedData) : [];
          setQuestions(questionList);
        }}
      />
    </Layout>
  );
}
