import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import NewEvaluationQuestionGroup from 'modules/NewEvaluationQuestionGroup';

function NewEvaluationQuestionGroupPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('New Evaluation Question Group'))
  }, []);

  return (
    <Layout>
      <NewEvaluationQuestionGroup />
    </Layout>
  );
}

export default NewEvaluationQuestionGroupPage;
