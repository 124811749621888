/* eslint-disable max-len */
import React, { useState } from 'react'
import FormView, { IFormItem } from 'components/FormView';
import ViewFormLayout from 'components/ViewFormLayout';
import {
  Country, Division, EmploymentType, School, SpecialtyTypes,
  UpdateUserProfileRequest,
} from 'services/interfaces';
import { Button } from '@mui/material';
import { IState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import { BUTTON_TEXT, PROFILE_TEXT } from 'utils/constants';

interface IFormResponse {
  id: string;
  value: any;
}

export function updateArrayState<T extends { id: string }>(
  prevState: T[],
  newResponse: T,
): T[] {
  const existingIndex = prevState.findIndex((item) => item.id === newResponse.id);

  if (existingIndex !== -1) {
    const updatedState = [...prevState];
    updatedState[existingIndex] = newResponse;
    return updatedState;
  }
  return [...prevState, newResponse];
}

function extractValues(
  accountStatus: IFormResponse[],
  personalInformation: IFormResponse[],
  employeeInformation: IFormResponse[],
): UpdateUserProfileRequest {
  const initialUserDetails: { [key: string]: string | number } = {}

  const allInformation = [...accountStatus, ...personalInformation, ...employeeInformation];

  allInformation.forEach((curr) => {
    if (curr?.id === 'schoolId') {
      initialUserDetails[curr?.id] = Number(curr?.value)
    }
    initialUserDetails[curr?.id] = curr?.value as string
  });
  const finalUserDetails: UpdateUserProfileRequest = {
    ...initialUserDetails,
    userId: 0,
  };

  return finalUserDetails;
}

interface IAccountInformation {
  onSubmitForm: Function,
  countries: Country[]
  schools: School[]
  employmentType: EmploymentType[]
  specialtyType: SpecialtyTypes[]
  divisions: Division[]
}
export default function AccountInformation({
  countries,
  schools, employmentType, specialtyType, divisions, onSubmitForm,
}: IAccountInformation) {
  const user = useSelector((state: IState) => state.userProfile.user);

  const [accountStatus, setAccountStatus] = useState<IFormResponse[]>([])
  const [personalInformation, setPersonalInformation] = useState<IFormResponse[]>([])
  const [employeeInformation, setEmployeeInformation] = useState<IFormResponse[]>([]);

  const handleAccountStatusChange = (newResponse: IFormResponse) => {
    setAccountStatus((prevState) => updateArrayState(prevState, newResponse));
  };

  const handlePersonalInformationChange = (newResponse: IFormResponse) => {
    setPersonalInformation((prevState) => updateArrayState(prevState, newResponse));
  };

  const handleEmployeeInformationChange = (newResponse: IFormResponse) => {
    setEmployeeInformation((prevState) => updateArrayState(prevState, newResponse));
  };

  const handleSubmit = () => {
    if (onSubmitForm) {
      onSubmitForm(extractValues(accountStatus, personalInformation, employeeInformation))
    }
  };

  const AccountStatusForm: IFormItem[] = [
    {
      editable: false,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: user?.active ? 'true' : undefined,
      fieldId: 'activeLogin',
      label: 'Active',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: user?.lastLogin,
      fieldId: 'lastLogin',
      label: 'Last Login',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: user?.loginAsLink,
      fieldId: 'loginAsLink',
      label: 'Login As Link',
      titleSize: 3,
    },
  ];

  const personalInformationForm: IFormItem[] = [
    {
      editable: false,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: user?.userName,
      fieldId: 'UserName',
      label: 'User Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: user?.firstName,
      fieldId: 'FirstName',
      label: 'First Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: user?.middleName,
      fieldId: 'MiddleName',
      label: 'Middle Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: user?.lastName,
      fieldId: 'LastName',
      label: 'Last Name',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: user?.email,
      fieldId: 'homeEmail',
      label: 'Email',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: user?.city,
      fieldId: 'city',
      label: 'City',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: [],
      fieldValue: user?.state,
      fieldId: 'state',
      label: 'State',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: user?.zip,
      fieldId: 'zipCode',
      label: 'ZIP',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'select',
      options: countries?.map((country: Country) => ({ key: country.countryCode, value: country.countryName })),
      fieldValue: user?.countryCode,
      fieldId: 'countryCode',
      label: 'Country',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: String(user?.birthDate),
      fieldId: 'birthDate',
      label: 'Birth Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: user?.gender,
      fieldId: 'gender',
      label: 'Gender',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: user?.workPhoneNumber,
      fieldId: 'workPhone',
      label: 'Work Phone',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: user?.homePhoneNumber,
      fieldId: 'homePhone',
      label: 'Mobile Phone',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'select',
      options: schools?.map((school: School) => ({ key: school.id, value: school.name })),
      fieldValue: user?.schoolId,
      fieldId: 'schoolId',
      label: 'School',
      titleSize: 3,
    },

  ];

  const employeeInformationForm: IFormItem[] = [
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(user?.employeeId),
      fieldId: 'EmployeeID',
      label: 'Employee ID',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: user?.employeeBadgeNumber,
      fieldId: 'EmployeeBadgeNumber',
      label: 'Badge ID',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'select',
      options: employmentType?.map((emT: EmploymentType) => ({ key: emT.id, value: emT.name })),
      fieldValue: user?.employmentTypeId,
      fieldId: 'employmentTypeId',
      label: 'Employment Type',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      options: divisions?.map((dv: Division) => ({ key: dv.divisionId, value: dv.divisionName })),
      fieldValue: user?.divisionId,
      fieldId: 'divisionId',
      label: 'Division',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'date',
      fieldValue: String(user?.dateHired),
      fieldId: 'DateHired',
      label: 'Hire Date',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: String(user?.promotionDate),
      fieldId: 'PromotionDate',
      label: 'Promotion Date',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: String(user?.terminationDate),
      fieldId: 'TerminationDate',
      label: 'Termination Date',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: String(user?.rehireDate),
      fieldId: 'RehireDate',
      label: 'Rehired Date',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      options: employmentType?.map((emT: EmploymentType) => ({ key: emT.id, value: emT.name })),
      fieldValue: user?.position,
      fieldId: 'position',
      label: 'Position',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: user?.supervisorLevel,
      fieldId: 'SupervisorLevel',
      label: 'Supervisor Level',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'select',
      options: specialtyType?.map((esp: SpecialtyTypes) => ({ key: esp.id, value: esp.name })),
      fieldValue: user?.specialtyId,
      fieldId: 'specialtyId',
      label: 'Specialty Type',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: user?.credentialName,
      fieldId: 'credentialName',
      label: 'Credential Type',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: user?.subStatusTypeId,
      fieldId: 'subStatus',
      label: 'Employment Sub Type',
      titleSize: 3,
    },

    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: user?.salaryGrade,
      fieldId: 'SalaryGrade',
      label: 'Salary Grade',
      titleSize: 3,
    },
  ];

  const classificationsForm: IFormItem[] = [
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: user?.cdlClassificationTypeId,
      fieldId: 'cdlClassificationTypeId',
      label: 'CDL Classification',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: user?.cdlExpirationDate,
      fieldId: 'cdlExpirationDate',
      label: 'CDL Expiration Date',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: user?.safetySensitive,
      fieldId: 'safetySensitive',
      label: 'Safety Sensitive',
      titleSize: 3,
    },
  ]

  return (
    <>
      <ViewFormLayout testId="account-status">
        <ViewFormLayout.ActionBar>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!accountStatus?.length && !personalInformation?.length && !employeeInformation?.length}
          >
            {BUTTON_TEXT.SAVE}
          </Button>
        </ViewFormLayout.ActionBar>
        <ViewFormLayout.Title>
          {PROFILE_TEXT.ACCOUNT_STATUS}
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={AccountStatusForm}
            onChangeValues={(obj: IFormResponse) => handleAccountStatusChange(obj)}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
      <ViewFormLayout testId="personal-information">
        <ViewFormLayout.Title>
          {PROFILE_TEXT.PERSONAL_INFORMATION}
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={personalInformationForm}
            onChangeValues={(obj: IFormResponse) => handlePersonalInformationChange(obj)}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
      <ViewFormLayout testId="employee-information">
        <ViewFormLayout.Title>
          {PROFILE_TEXT.EMPLOYEE_INFORMATION}
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={employeeInformationForm}
            onChangeValues={(obj: IFormResponse) => handleEmployeeInformationChange(obj)}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
      <ViewFormLayout testId="classifications">
        <ViewFormLayout.Title>
          {PROFILE_TEXT.CLASSIFICATIONS}
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={classificationsForm}
            onChangeValues={(obj: { id: string, value: any }) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
    </>
  )
}
