import React, { useEffect } from 'react';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useDispatch } from 'react-redux';
import Edit from 'modules/AdminTestLibraries/Questions//Edit';
import Layout from 'components/Layout';

export default function QuestionDetailPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('Question detail'));
  }, []);
  return (
    <Layout>
      <Edit />
    </Layout>
  );
}
