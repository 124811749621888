import { Box } from '@mui/material';
import React from 'react'
import ReactPlayer from 'react-player'
import { isAPDF, isAVideo } from 'utils/helpers';

interface MyCourseContentProps {
  resource: string;
}

export default function MyCourseContent({ resource }: MyCourseContentProps) {
  if (
    isAVideo(resource)
  ) {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={resource}
          width="100%"
          height="100%"
          controls
        />
      </div>

    )
  }

  if (isAPDF(resource)) {
    return (
      <Box height="800px" width="100%">
        <object data={resource} type="application/pdf" width="100%" height="100%">
          <p>
            Alternative text - include a link
            <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a>
          </p>
        </object>
      </Box>
    )
  }

  return (
    <div>
      This file is not supported for live preview
    </div>
  )
}
