import React, { useState } from 'react';
import {
  Box, IconButton, Tooltip, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import CustomTable from 'components/CustomTable';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
// eslint-disable-next-line import/extensions
import groups from '../groups.json';

interface Group{
  groupId: number;
  Name: string;
  Description: string;
  UserCount: number;
}

function getActionButtons(_navigate: Function) {
  return (
    <Box display="flex">
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.EDIT_GROUP}>
        <IconButton onClick={() => _navigate('/admin/groups/new')} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <EditIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.VIEW_GROUP}>
        <IconButton onClick={() => _navigate('/admin/groups/new')} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <VisibilityIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

function getUserCountButton(count: number, _navigate: Function) {
  return (
    <Box display="flex">
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.MORE_INFO}>
        <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => _navigate('/admin/groups/users')}>
          <GroupIcon sx={{ color: (theme) => theme.palette.primary.main }} />
          <Typography color={(theme) => theme.palette.primary.main}>
            View (
            {count}
            )
          </Typography>
        </IconButton>
      </Tooltip>
    </Box>
  )
}
function createData(items: Group[], _navigate: Function): TRows[] {
  return items?.map((item: Group) => ({
    ...item,
    details: [],
    id: item.groupId,
    isCollapsable: false,
    UserCount: getUserCountButton(item.UserCount, _navigate),
    Actions: getActionButtons(_navigate),
  }));
}
export default function UsersTable() {
  const navigate = useNavigate();
  const [dataSet] = useState<TRows[] | []>(createData(groups, navigate));
  const columns: TColumns[] = [
    {
      name: 'groupId',
      column: 'groupId',
      width: 200,
      sortable: true,
    },
    {
      name: 'Name',
      column: 'Name',
      width: 120,
      sortable: true,
    },
    {
      name: 'Description',
      column: 'Description',
      width: 120,
      sortable: false,
    },
    {
      name: 'User Count',
      column: 'UserCount',
      width: 120,
      sortable: false,
    },
    {
      name: 'Actions',
      column: 'Actions',
      width: 120,
      sortable: false,
    },
  ];
  return (
    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      showSelectCheckbox={false}
    />
  )
}
