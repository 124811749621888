import React from 'react';
import { Button, Tooltip } from '@mui/material';
import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import GroupsTable from './GroupsTable/GroupsTable';

export default function ManageGroups() {
  const navigate = useNavigate();
  return (
    <ViewFormLayout pl={0} pr={0} testId="manage-groups-table">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.NEW_GROUP}>
          <Button onClick={() => navigate('/admin/groups/new')} variant="contained">{BUTTON_TEXT.NEW_GROUP}</Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <GroupsTable />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
