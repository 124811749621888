/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  COURSE_TYPE_ID, ACTION_ITEM_TOOLTIP_TEXT, COURSE_TYPES_ENUM, COURSE_STATUS_BY_DATE,
  FILTER_KEY,
  PROFILE_TEXT,
} from 'utils/constants';
import { Chip, Stack, Typography } from '@mui/material';
import {
  categorizeByDueDate, filterCoursesByDate, filterRowsByField, formatDate, getRequirmentLabel,
} from 'utils/helpers';
import { CourseDetails, GetAllCoursesByUserIdRequest, GetAllCoursesByUserIdResponse } from 'services/interfaces';
import { IState } from 'redux/interfaces';
import { LearningObject } from 'modules/SearchLearningObject/shared';
import { TColumns, TRows, filterApplied } from 'components/CustomTable/interface';
import { useDispatch, useSelector } from 'react-redux';
import { useGetAllCoursesByUserIdMutation } from 'services/dataApi';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CustomTable from 'components/CustomTable';
import DialogModal from 'components/DialogModal';
import getType from 'components/TypeIcon';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import LoadingIcon from 'components/LoadingIcon';
import PreviewLaunch from 'modules/PreviewLaunch';
import Tooltip from '@mui/material/Tooltip';
import { removeFilters } from 'redux/userDashboardSlice';
import { grey } from '@mui/material/colors';
import getStatusIconByDueDate from './StatusIconLabel';

type PreviewLaunchType = Pick<LearningObject, 'wbtURL' | 'learningObjectName'>;

const CURSES_STATUS = ['Enrolled', 'Class Session Complete', 'Pending', 'Evaluation Complete', 'ROMS Completed', 'Scheduled', 'Waitlist']
export default function MyCourses() {
  const dispatch = useDispatch();
  const {
    filterByStatus,
    filterByDate,
  } = useSelector((state: IState) => state.userDashboard);
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);
  const [defaultFilter, setDefaultFilter] = useState<filterApplied[]>([]);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [previewSelected] = useState<PreviewLaunchType>();
  const [data, setData] = useState<GetAllCoursesByUserIdResponse>([]);
  const [dataSet, setDataSet] = useState<TRows[] | []>([]);
  const userId = useSelector((state: IState) => state.userProfile.userId);

  const [getAllCoursesByUserId, { isLoading }] = useGetAllCoursesByUserIdMutation();

  function getMoreInfoButton(learningInstanceId: number, url: string, courseName?: string) {
    return (
      <Tooltip title={`${courseName} more info` || ACTION_ITEM_TOOLTIP_TEXT.MORE_INFO}>
        <IconButton onClick={() => navigate(`${url}/${learningInstanceId}`)}>
          <InfoIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    )
  }

  function getActions(learningInstanceId: number, learningTypeId: number): React.ReactNode {
    if (learningTypeId === COURSE_TYPES_ENUM.ONLINE) {
      return (
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.COURSE_ACTIONS}>
          <Button variant="contained" onClick={() => navigate(`/dashboard/course/launch/${learningInstanceId}`)}>LAUNCH</Button>
        </Tooltip>
      )
    }
    return null;
  }

  function createBasicData(item: CourseDetails): TRows {
    const statusByDueDate = categorizeByDueDate(item.dueDate) as COURSE_STATUS_BY_DATE;
    const dueDateFormatted = item.dueDate ? formatDate(String(item.dueDate)) : '';
    return {
      id: item?.learningInstanceId,
      courseTypeIcon: getType({ type: item.learningTypeId }),
      moreInfoIcon: getMoreInfoButton(item?.learningInstanceId, '/dashboard/certification/detail', item.learningName),
      courseType: COURSE_TYPE_ID[item.learningTypeId],
      courseName: item.learningName,
      enrollmentType: item.learningProcessStatusTypeName,
      requirement: getRequirmentLabel(item?.dueDate),
      status: item.learningProcessStatusTypeName,
      dueDate: dueDateFormatted,
      dueDateStatus: statusByDueDate,
      dueDateIcon: getStatusIconByDueDate(statusByDueDate, dueDateFormatted),
      startDate: item.startDate ? formatDate(String(item.startDate)) : '',
      endDate: item.endDate ? formatDate(String(item.endDate)) : '',
      moreInfo: getMoreInfoButton(item?.learningInstanceId, '/dashboard/course/detail'),
      actions: getActions(item?.learningInstanceId, item.learningTypeId),
      isCollapsable: item.isCollapsable,
      details: [],
    };
  }

  function getValidTimestamps(dates: (string | undefined)[]) {
    return dates
      .filter((date) => date !== undefined)
      .map((date) => new Date(date!).getTime());
  }

  function createData(item: CourseDetails): any {
    if (item.isCollapsable) {
      const basicData = createBasicData(item);
      const details = [...item.learningDetails || []];

      const validStartDates = getValidTimestamps(details.map((item) => item.startDate));
      const validDueDates = getValidTimestamps(details.map((item) => item.dueDate));

      const minStartDate = validStartDates.length ? Math.min(...validStartDates) : null;
      const maxDueDate = validDueDates.length ? Math.max(...validDueDates) : null;
      const detail = details?.map((_item: any) => createBasicData(_item)) || [];
      const startDate = minStartDate ? formatDate(String(new Date(minStartDate))) : null;
      const dueDate = maxDueDate ? formatDate(String(new Date(maxDueDate))) : null;

      let name = item.learningName;
      if (startDate && dueDate) {
        name = `${item.learningName} (${startDate} to ${dueDate})`;
      }

      return {
        ...basicData,
        courseName: name,
        startDate,
        dueDate,
        details: [
          ...detail,
        ],
      }
    }
    return createBasicData(item);
  }

  function updateDefaultFilter(newFilter: filterApplied) {
    setDefaultFilter((prevFilters) => {
      const filterExists = prevFilters.findIndex((filter) => filter.filterId === newFilter.filterId);

      if (filterExists !== -1) {
        // Replace the existing filter
        const updatedFilters = [...prevFilters];
        updatedFilters[filterExists] = newFilter;
        return updatedFilters;
      }
      // Add the new filter
      return [...prevFilters, newFilter];
    });
  }

  function removeFilter(filterIdToRemove: string) {
    setDefaultFilter((prevFilters) => prevFilters.filter((filter) => filter.filterId !== filterIdToRemove));
  }

  function preProcessCourses(dataCourses: GetAllCoursesByUserIdResponse): TRows[] {
    let tableData = [];
    if (dataCourses?.length > 0) {
      const tableDataByType: GetAllCoursesByUserIdResponse = dataCourses;

      tableData = tableDataByType.length > 0
        ? tableDataByType?.map((item: CourseDetails) => createData(item)) : [];
    }

    if (filterByDate) {
      tableData = filterCoursesByDate(tableData, filterByDate)
      updateDefaultFilter({
        filterId: FILTER_KEY.RemoveDate,
        filterActionId: FILTER_KEY.RemoveDate,
        filterIsGroup: false,
        filterLabel: 'Date',
        filterValue: [filterByDate],
      })
    }
    if (filterByStatus) {
      const filter = {
        filterId: 'dueDateStatus',
        filterActionId: FILTER_KEY.RemoveStatus,
        filterIsGroup: false,
        filterLabel: 'Due date Status',
        filterValue: [filterByStatus],
      }
      tableData = filterRowsByField(tableData, null, filter)
      updateDefaultFilter(filter)
    }

    return tableData;
  }

  const fetchData = async (request: GetAllCoursesByUserIdRequest) => {
    try {
      const response = await getAllCoursesByUserId(request).unwrap();
      setData(response)
      setDataSet(preProcessCourses(response));
    } catch (err) {
      setError('Error fetching data');
      setDataSet([]);
    }
  };

  useEffect(() => {
    const request: GetAllCoursesByUserIdRequest = {
      userId,
      learningProcessStatus: CURSES_STATUS,
    };
    fetchData(request);
  }, []);

  useEffect(() => {
    setDataSet(preProcessCourses(data));
  }, [filterByStatus, filterByDate])

  const columns: TColumns[] = [
    {
      name: 'Course Type',
      column: 'courseTypeIcon',
      width: 10,
      alignTitle: 'left',
      sortable: false,
      hidden: false,
      searchable: true,
      filter: true,
      filterLabel: 'Course Type',
    },
    {
      name: 'Course Name',
      column: 'courseName',
      width: 300,
      alignTitle: 'left',
      sortable: true,
      hidden: false,
      searchable: true,
    },
    {
      name: 'Requirement',
      column: 'requirement',
      width: 100,
      align: 'center',
      alignTitle: 'center',
      sortable: false,
      hidden: false,
      filter: true,
      filterLabel: 'Requirement',
    },
    {
      name: 'Status',
      column: 'status',
      width: 100,
      align: 'left',
      alignTitle: 'left',
      sortable: false,
      hidden: false,
      filter: true,
      filterLabel: 'Status',
    },
    {
      name: 'Start Date',
      column: 'startDate',
      width: 100,
      align: 'left',
      alignTitle: 'left',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Due Date',
      column: 'dueDateIcon',
      width: 100,
      align: 'left',
      alignTitle: 'left',
      sortable: true,
      hidden: false,
    },

    {
      name: 'More Info',
      column: 'moreInfo',
      width: 50,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Actions',
      column: 'actions',
      width: 100,
      align: 'center',
      alignTitle: 'center',
      sortable: false,
      hidden: false,
    },
  ];

  const getExternalFilters = () => (
    <>
      {React.Children.toArray(defaultFilter.map((filter: filterApplied) => (
        <Stack flexDirection="row" justifyItems="center" alignItems="center" p={1} gap={1} display={{ border: `1px dotted ${grey[200]}`, borderRadius: '8px' }}>
          <Typography variant="subtitle2">{`${filter?.filterLabel}:`}</Typography>
          {React.Children.toArray(filter.filterValue.map((val) => (
            <Chip
              label={val}
              variant="outlined"
              onDelete={() => {
                removeFilter(filter?.filterId)
                dispatch(removeFilters(filter?.filterActionId))
              }}
            />
          )))}
        </Stack>
      )))}
    </>
  )
  return isLoading
    ? <LoadingIcon />
    : (
      <>
        <Typography>{error}</Typography>
        <CustomTable
          collapseAllRow={false}
          columns={columns}
          defaultSort="dueDate"
          filterByParentGroup={{ key: 'courseTypeGroup', label: 'Groups' }}
          colSpanGroupTitle={5}
          rows={dataSet}
          showColumnsControl={false}
          showSearchBar
          externalFilters={getExternalFilters()}
          onClearAllFilters={() => {
            dispatch(removeFilters(''))
            setDefaultFilter([])
          }}
        />
        <DialogModal open={openPreview} onClose={() => setOpenPreview(false)}>
          <DialogModal.Title>
            {PROFILE_TEXT.COURSE_LAUNCHER}
          </DialogModal.Title>
          <DialogModal.Content>
            <PreviewLaunch previewSelected={previewSelected as PreviewLaunchType} />
          </DialogModal.Content>
        </DialogModal>
      </>
    )
}
