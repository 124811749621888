/* eslint-disable max-len */
import React from 'react'
import {
  Container,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Box,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { formatDate, generateRandomKey } from 'utils/helpers';
import getType from 'components/TypeIcon';
import { COURSE_TYPES_ENUM } from 'utils/constants';
import { LearningObject } from './shared';

interface IObjectCard {
  learningObject: LearningObject,
  onOpenCertificationDetail: Function,
  onOpenEnrolledSelfDetail: Function,
}
export default function ObjectCard(
  {
    learningObject, onOpenCertificationDetail, onOpenEnrolledSelfDetail,
  }:IObjectCard,
) {
  const ilt = learningObject.learningObjectTypeId === COURSE_TYPES_ENUM.CLASSROOM;
  const online = learningObject.learningObjectTypeId === COURSE_TYPES_ENUM.ONLINE;
  // const certificate = learningObject.LearningObjectTypeId === COURSE_TYPES_ENUM.CERTIFICATION;

  // const pending = learningObject.LearningObjectProcessStatusTypeID
  //   === LearningObjectProcessStatusTypeID.Pending;
  // const enrolled = learningObject.LearningObjectProcessStatusTypeID
  //   === LearningObjectProcessStatusTypeID.Enrolled;
  // const completed = learningObject.LearningObjectProcessStatusTypeID
  //   === LearningObjectProcessStatusTypeID.Completed;

  const getActionButtons = (_learningObject: LearningObject) => {
    const type = _learningObject.learningObjectTypeId;
    const id = _learningObject.learningObjectInstanceId;
    const name = _learningObject.learningObjectName;

    if ((type === COURSE_TYPES_ENUM.CERTIFICATION || Number(type) === COURSE_TYPES_ENUM.CERTIFICATION) && id > 0) {
      return (
        <Box display="flex" alignItems="center">
          <Tooltip
            title="Details"
          >
            <IconButton onClick={() => onOpenCertificationDetail(id, name)}>
              <InfoIcon sx={{ color: (theme) => theme.palette.primary.main }} />
            </IconButton>
          </Tooltip>
        </Box>
      );
    }
    if (type === COURSE_TYPES_ENUM.ONLINE || Number(type) === COURSE_TYPES_ENUM.ONLINE) {
      return (
        <Box display="flex" alignItems="center">
          <Tooltip
            title="Detail enroll (Selft)"
          >
            <IconButton onClick={() => onOpenEnrolledSelfDetail(_learningObject)}>
              <InfoIcon sx={{ color: (theme) => theme.palette.primary.main }} />
            </IconButton>
          </Tooltip>
        </Box>
      );
    }
    if (type === COURSE_TYPES_ENUM.CLASSROOM || Number(type) === COURSE_TYPES_ENUM.CLASSROOM) {
      return (
        <Box display="flex" alignItems="center">
          <Tooltip
            title="Detail enroll (Selft)"
          >
            <IconButton onClick={() => onOpenEnrolledSelfDetail(_learningObject)}>
              <InfoIcon sx={{ color: (theme) => theme.palette.primary.main }} />
            </IconButton>
          </Tooltip>
        </Box>
      );
    }
    return null;
  }
  return (
    <Container
      data-testid="learning-object"
      key={generateRandomKey(learningObject.learningObjectInstanceId, 'header')}
      maxWidth={false}
      sx={{
        overflow: 'hidden',
      }}
    >
      <Grid container>
        <Grid
          item
          md={11}
          sx={{
            overflow: 'hidden',
          }}
        >
          <Grid
            container
            sx={{
              overflow: 'hidden',
            }}
          >
            <Grid item md={12}>
              <Typography variant="subtitle1">
                {getType({ type: learningObject.learningObjectTypeId })}
              </Typography>
            </Grid>
            <Grid item md={12} display="flex" flexDirection="row" justifyContent="space-between">
              <Box
                key={generateRandomKey(learningObject.learningObjectInstanceId, 'item')}
                sx={{
                  padding: '4px 0px',
                }}
                data-testid="learning-Grid-box"
              >
                <div className="course-detail-container">
                  <Typography variant="subtitle1">
                    <strong>{learningObject.learningObjectName}</strong>

                  </Typography>

                  {ilt
                      && (
                      <>
                        <Typography variant="body2">
                          <strong>{'Instructor Name: '}</strong>
                          {learningObject?.instructorSummary}
                        </Typography>
                        <Box className="box-flex-row">
                          <Typography variant="body2">
                            <strong>{'Start Date: '}</strong>
                            {learningObject?.startDateTime ? formatDate(learningObject?.startDateTime) : ' - '}
                          </Typography>
                          <Typography variant="body2">
                            <strong>{'End Date: '}</strong>
                            {learningObject?.endDateTime ? formatDate(learningObject?.endDateTime) : ' - '}
                          </Typography>
                        </Box>
                      </>
                      )}
                  <Typography variant="body2">
                    <strong>{'Duration: '}</strong>
                    {(online || ilt)
                      ? `${learningObject.duration || 0} hours`
                      : `${learningObject.numberOfDays || 0} days`}
                  </Typography>
                  <Typography variant="body2">
                    <strong>{'Description: '}</strong>
                    {learningObject.learningObjectDescription}
                  </Typography>
                </div>
              </Box>
              <div className="btn-container" data-testid="learning-Grid-btn">
                {getActionButtons(learningObject)}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
