/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
import { Button, Stack, Tooltip } from '@mui/material';
import { IState } from 'redux/interfaces';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetAllCoursesByUserIdMutation } from 'services/dataApi';
import { useNavigate } from 'react-router-dom';
import Layout from 'components/Layout';
import LoadingIcon from 'components/LoadingIcon';
import ViewFormLayout from 'components/ViewFormLayout';
import MyPastCourses from 'modules/InformationCourses/MyPastCourses';
import { GetAllCoursesByUserIdRequest, GetAllCoursesByUserIdResponse } from 'services/interfaces';

const PAST_COURSES = ['Completed', 'Cancelled By User', 'No Show', 'Cancelled By Authorizer', 'Cancelled By Admin', 'Denied By Authorizer', 'Denied By Admin', 'Completed (Failed)', 'Employment Terminated', 'Course Expired', 'ECard Issued'];
function LoadingState() {
  return (
    <Stack sx={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
    }}
    >
      <LoadingIcon />
    </Stack>
  )
}

function MyTranscriptPage() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { userId } = useSelector((state: IState) => state.userProfile);
  const [data, setData] = useState<GetAllCoursesByUserIdResponse>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getAllCoursesByUserId] = useGetAllCoursesByUserIdMutation();

  const fetchData = async (request: GetAllCoursesByUserIdRequest) => {
    try {
      setIsLoading(true)
      const response = await getAllCoursesByUserId(request).unwrap();
      setData(response)
    } catch (err) {
      setData([]);
    }
    setIsLoading(false)
  };

  useEffect(() => {
    dispatch(setNavigationPage('My Transcript'))
    const request: GetAllCoursesByUserIdRequest = {
      userId,
      learningProcessStatus: PAST_COURSES,
    };
    fetchData(request);
  }, []);

  return (
    <Layout>
      <ViewFormLayout testId="user-dashboard-past-courses">
        <ViewFormLayout.ActionBar>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="contained" onClick={() => navigate('/transcript-report')}>Print my transcript</Button>
          </Tooltip>
        </ViewFormLayout.ActionBar>
        <ViewFormLayout.Body>
          <Stack p={2}>
            { isLoading ? <LoadingState /> : <MyPastCourses dataCourses={data} />}
          </Stack>
        </ViewFormLayout.Body>
      </ViewFormLayout>

    </Layout>
  );
}

export default MyTranscriptPage;
