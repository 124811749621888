/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { Box, Button, Tooltip } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from 'components/Layout';
import TestLibraryForm from 'modules/AdminTestLibraries/TestLibraryDetail/TestLibraryForm';
import useInternalStorage from 'hooks/useInternalStorage';
import ViewFormLayout from 'components/ViewFormLayout';

interface IFormValue {
  id: string, value: any
}

export default function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, addTestLibraryValue] = useInternalStorage<any[]>('testLibraryList', []);

  const [formValue, setFormValue] = useState<IFormValue[]>([]);

  const handleFormResponse = (obj: { id: string, value: any }) => {
    setFormValue((_values) => {
      const existingIndex = _values?.findIndex((item) => item.id === obj.id);
      return existingIndex !== -1
        ? _values?.map((item, index) => (index === existingIndex ? obj : item))
        : [..._values, obj];
    })
  };

  const handleSave = () => {
    const testLibrary = formValue?.reduce((result, item) => {
      result[item.id] = item.value;
      return result;
    }, {});
    const id = Math.floor(Math.random() * 999999) + 1;
    addTestLibraryValue(id, testLibrary)
    enqueueSnackbar('Test Saved Successfully', { variant: 'success' });
    navigate(`/admin/test-libraries/detail/${id}`)
  };

  useEffect(() => {
    dispatch(setNavigationPage('New Test Library'));
  }, []);
  return (
    <Layout>
      <ViewFormLayout testId="new-test-library">
        <ViewFormLayout.ActionBar>
          <Box>
            <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
              <Button variant="contained" onClick={handleSave}>{BUTTON_TEXT.SAVE}</Button>
            </Tooltip>
            <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
              <Button variant="text" onClick={() => navigate('/admin/test-libraries')}>{BUTTON_TEXT.BACK}</Button>
            </Tooltip>
          </Box>
        </ViewFormLayout.ActionBar>
        <ViewFormLayout.Body>
          <TestLibraryForm
            onCallback={(obj: IFormValue) => handleFormResponse(obj)}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
    </Layout>
  );
}
