/* eslint-disable max-len */
import React, { useState } from 'react';
import { TColumns, TRows } from 'components/CustomTable/interface';
import CustomTable from 'components/CustomTable';
import getType from 'components/TypeIcon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import courses from '../courses.json'

function getViewButton(id: string | number, _navigate: Function, count: number) {
  return (
    <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.VIEW_CLASS_USERS}>
      <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => _navigate('users')}>
        <GroupIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        <Typography color={(theme) => theme.palette.primary.main}>
          View (
          {count}
          )
        </Typography>
      </IconButton>
    </Tooltip>
  )
}

function getActionButtons(id: string | number, _navigate: Function) {
  return (
    <Box display="flex">
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.EDIT_COURSE}>
        <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => _navigate(`detail/${id}`)}>
          <EditIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

function createData(items: any[], navigate: Function): TRows[] {
  return items?.map((item: any) => ({
    ...item,
    type: getType({ type: item?.type }),
    classCount: getViewButton(item?.id, navigate, item?.studentCount),
    details: [],
    isCollapsable: false,
    courseName: '',
    actions: getActionButtons(item.id, navigate),
  }));
}

export default function CoursesTable() {
  const navigate = useNavigate();
  const [dataSet] = useState<TRows[] | []>(createData(courses, navigate));

  const columns: TColumns[] = [
    {
      name: 'ID',
      column: 'id',
      width: 100,
      sortable: true,
    },
    {
      name: 'Course Number',
      column: 'courseNumber',
      width: 150,
      sortable: true,
      align: 'center',
      alignTitle: 'center',
    },
    {
      name: 'Name',
      column: 'name',
      width: 200,
      sortable: true,
      searchable: true,
    },
    {
      name: 'Student Count',
      column: 'studentCount',
      width: 120,
      align: 'center',
      alignTitle: 'center',
      sortable: true,
    },
    {
      name: 'Type',
      column: 'type',
      width: 150,
      sortable: false,
    },
    {
      name: 'Class Count',
      column: 'classCount',
      width: 120,
      sortable: false,
    },
    {
      name: 'Actions',
      column: 'actions',
      width: 120,
      sortable: false,
    },
  ];
  return (
    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      defaultSort="dueDate"
      showSearchBar
      showSelectCheckbox
    />
  )
}
