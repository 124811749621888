/* eslint-disable max-len */
import { blue } from '@mui/material/colors';
import { UserStatsResponse } from 'services/interfaces';

export interface IMetricsCard {
  cardTestId: string;
  metricName: string;
  metricValue: string | number;
  subMetric1?: string | number;
  themeColor: 'error' | string;
  subMetric2?: string | number;
  subMetric3?: string | number;
  action?: Function;
}

export function splitMetrics(metrics: UserStatsResponse) {
  const metricCard = [];

  metricCard.push({
    cardTestId: 'total-enrollments',
    metricName: 'Total Enrollments',
    metricValue: metrics.totalOfMyEnrollments,
    themeColor: blue[700],
  });

  metricCard.push({
    cardTestId: 'incomplete-course-evaluations',
    metricName: 'Incomplete Course Evaluations',
    metricValue: metrics?.totalOfMyIncompleteCourseEvaluations || 0,
    themeColor: blue[700],
  });

  metricCard.push({
    cardTestId: 'certification-expiration',
    metricName: 'Certifications Expired',
    metricValue: metrics.totalOfMyUpcomingCertificationExpiration || 0,
    themeColor: blue[700],
  });

  metricCard.push({
    cardTestId: 'certification-expired',
    metricName: 'Certifications Expiring Soon',
    metricValue: metrics.totalOfMyCertificationExpiration || 0,
    themeColor: blue[700],
  });

  return metricCard;
}
