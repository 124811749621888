/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { Button, Stack, Tooltip } from '@mui/material';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from 'components/Layout';
import ViewFormLayout from 'components/ViewFormLayout';
import UserTranscript from 'modules/UserTranscript';

function MyTranscriptReportPage() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setNavigationPage('My Transcript Report'))
  }, []);

  return (
    <Layout>
      <ViewFormLayout testId="user-dashboard-certifications">
        <ViewFormLayout.ActionBar>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="text" onClick={() => navigate('/transcript')}>{BUTTON_TEXT.BACK}</Button>
          </Tooltip>
        </ViewFormLayout.ActionBar>
        <ViewFormLayout.Body>
          <Stack p={2}>
            <UserTranscript />
          </Stack>
        </ViewFormLayout.Body>
      </ViewFormLayout>

    </Layout>
  );
}

export default MyTranscriptReportPage;
