import React, { useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import './UserTranscript.scss';
import { useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import { useGetYellowFinTokenQuery } from 'services/dataApi';
import { Grid } from '@mui/material';
import Widget from 'components/Widget';

export default function UserTranscript() {
  const userId = useSelector((state: IState) => state.userProfile.userId);
  const {
    myTranscriptReportId, yellowfinClientOrgRef,
  } = useSelector((state: IState) => state.userProfile.yellowFin)
  const containerRef = useRef(null);
  const initialized = useRef(false)

  const {
    data: yellowfinSSOResponse,
    isLoading: isLoadingYellowfinSSOToken,
  } = useGetYellowFinTokenQuery(yellowfinClientOrgRef);

  useEffect(() => {
    if (!initialized.current && containerRef.current && !isLoadingYellowfinSSOToken) {
      const yfLoginToken = yellowfinSSOResponse.securityToken;
      initialized.current = true;
            /* eslint-disable */
            document.querySelector('div#reportDiv')!.innerHTML = '';
            window['yellowfin'].init().then(() => {
                window['yellowfin'].newSession(yfLoginToken, yellowfinClientOrgRef).then(() => {
                    window['yellowfin'].loadReport({
                        reportId: myTranscriptReportId,
                        element: document.querySelector('div#reportDiv'),
                        showToolbar: true,
                        filterValues: [{
                            filterId: 'LMS User ID',
                            valueOne: userId,
                        }]
                    });
                });
            });
            /* eslint- */
        }
    }, [yellowfinSSOResponse]);

    return (
        <Grid
            container
            spacing={1}
        >
            <Widget columns={12} onMdColumns={5} height="1650px">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                >
                    <div id="reportDiv" ref={containerRef} />
                </Box>
            </Widget>
        </Grid>

    );
}
