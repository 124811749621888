/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckBoxIcon2 from '@mui/icons-material/CheckBox';
import FolderIcon from '@mui/icons-material/Folder';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import TreeView, {
  INodeRendererProps, flattenTree, ITreeViewOnNodeSelectProps,
  NodeId,
} from 'react-accessible-treeview';
import cx from 'classnames';
import { some } from 'lodash';

import './catalog.scss';

// eslint-disable-next-line import/extensions
import { useGetCatalogTreeItemsQuery } from 'services/dataApi';
import LoadingIcon from 'components/LoadingIcon';

function ArrowIcon({ isOpen }: {isOpen: boolean}) {
  const baseClass = 'arrow';
  const classes = cx(
    baseClass,
    { [`${baseClass}--closed`]: !isOpen },
    { [`${baseClass}--open`]: isOpen },
  );
  return <ArrowRightIcon className={classes} />;
}

const ROOT_CATALOG_NAME = 'Catalog';
const ITEM_INDENTATION = 57;

enum CheckBoxStatus {
  ALL_CHECKED = 'all',
  NONE_CHECKED = 'none',
  SOME_CHECKED = 'some'
}

interface ICheckBoxIconProps{
  variant: CheckBoxStatus;
  className: string;
  onClick: any;
}

function CheckBoxIcon({ variant, className, onClick }: ICheckBoxIconProps) {
  switch (variant) {
    case CheckBoxStatus.ALL_CHECKED:
      return <CheckBoxIcon2 className={className} onClick={onClick} />;
    case CheckBoxStatus.NONE_CHECKED:
      return <CheckBoxOutlineBlankIcon className={className} onClick={onClick} />;
    case CheckBoxStatus.SOME_CHECKED:
      return <IndeterminateCheckBoxIcon className={className} onClick={onClick} />;
    default:
      return null;
  }
}

const isHalfSelectedToString = (isHalfSelected: boolean, isSelected: boolean) => {
  if (isHalfSelected) return CheckBoxStatus.SOME_CHECKED;

  if (isSelected) return CheckBoxStatus.ALL_CHECKED;

  return CheckBoxStatus.NONE_CHECKED;
}

function Node({
  element,
  isBranch,
  isExpanded,
  isSelected,
  isHalfSelected,
  getNodeProps,
  level,
  handleExpand,
  handleSelect,
}: INodeRendererProps) {
  const handleClick = (event: React.MouseEvent) => {
    handleSelect(event);
    event.stopPropagation();
  }
  return (
    <div
      {...getNodeProps({ onClick: handleExpand })}
      style={{ marginLeft: ITEM_INDENTATION * (level - 1) }}
    >
      {isBranch && <ArrowIcon isOpen={isExpanded} />}
      {
        element.name !== ROOT_CATALOG_NAME && (
        <CheckBoxIcon
          className="checkbox-icon"
          variant={isHalfSelectedToString(isHalfSelected, isSelected)}
          onClick={handleClick}
        />
        )
      }

      <FolderIcon sx={{ color: (theme) => theme.palette.primary.main, verticalAlign: 'middle', marginRight: '0.3em' }} />
      <span className="name">{element.name}</span>
    </div>
  );
}

interface NodeFolder {
  id: NodeId;
  name: string;
}

interface CatalogProps {
  setTreeIds: (values: number[]) => void
}

export default function Catalog({ setTreeIds }: CatalogProps) {
  const {
    data,
    isLoading,
  } = useGetCatalogTreeItemsQuery();
  const [selectedNodes, setSelectedNodes] = useState<NodeFolder[]>([]);

  const handleNodeSelect = (selectProps: ITreeViewOnNodeSelectProps) => {
    const { id, name } = selectProps.element;
    if (some(selectedNodes, { id })) {
      const newNodes = selectedNodes.filter((item) => item.id !== id)
      setSelectedNodes(newNodes)
    } else {
      const newNodes = [...selectedNodes, { id, name }]
      setSelectedNodes(newNodes)
    }
  };

  // @ts-ignore
  const dataTree = flattenTree({ name: '', children: data })

  useEffect(() => {
    if (selectedNodes.length > 0) {
      const ids = selectedNodes.map((node) => node.id) as number[]
      setTreeIds(ids)
    }
  }, [selectedNodes])

  return (
    <div>
      {
        isLoading ? <LoadingIcon /> : (
          <div className="checkbox">
            <TreeView
              data={dataTree}
              aria-label="Checkbox tree"
              multiSelect
              propagateSelect
              propagateSelectUpwards
              togglableSelect
              nodeRenderer={Node}
              onNodeSelect={handleNodeSelect}
              defaultExpandedIds={[1]}
            />
          </div>
        )
      }
    </div>
  );
}
