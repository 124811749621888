import React, { useState } from 'react'
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box, Button, Tooltip,
} from '@mui/material';
import FormView, { IFormItem } from 'components/FormView';
import ViewFormLayout from 'components/ViewFormLayout';
// eslint-disable-next-line import/extensions
import scormCourses from '../scormCourses.json'
import ScormCourses from './ScormCourses';
import { IFormResponse } from './constants';

export default function ScormCoursesDetail() {
  const { scormCourseId } = useParams();
  const navigate = useNavigate();

  const [data] = useState(scormCourses?.find((co) => String(co?.id) === String(scormCourseId)));

  const AccountStatusForm: IFormItem[] = [
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.id),
      fieldId: 'id',
      label: 'Id',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.title),
      fieldId: 'title',
      label: 'Title',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: data?.inactive ? undefined : 'true',
      fieldId: 'inactive',
      label: 'Active',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'file',
      options: undefined,
      fieldValue: null,
      fieldId: 'zipFile',
      label: 'ZIP',
      titleSize: 3,
    },
  ]

  const scormFormLearning: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'select',
      options: [
        { key: 'SingleSCORM', value: 'SingleSCORM' },
      ],
      fieldValue: null,
      fieldId: 'wbtType',
      label: 'WBT Type',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: true,
      type: 'select',
      options: [
        { key: 'NotGraded', value: 'Not a graded course' },
      ],
      fieldValue: null,
      fieldId: 'grading',
      label: 'Grading',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: null,
      fieldId: 'passingScore',
      label: 'Passing Score',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: null,
      fieldId: 'maxStudent',
      label: 'Max Student',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'multiSelect',
      options: [],
      fieldValue: null,
      fieldId: 'ownerShip',
      label: 'Owner Ship',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'select',
      options: [
        { key: '1', value: 'Centerline' },
      ],
      fieldValue: null,
      fieldId: 'availability',
      label: 'Availability',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: true,
      type: 'select',
      options: [
        { key: '249', value: 'Online Courses' },
      ],
      fieldValue: null,
      fieldId: 'catalog',
      label: 'Catalog',
      titleSize: 3,
    },
  ];
  const handleFormResponse = (obj: { id: string, value: any }) => {
    console.log(obj.value)
  };

  const handleSave = () => {
    navigate('/admin/scorm-courses')
    enqueueSnackbar('SCORM Course Saved Successfully', { variant: 'success' });
  };

  return (
    <ViewFormLayout testId="advance">
      <ViewFormLayout.ActionBar>
        <Box>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
            <Button variant="contained" onClick={handleSave}>{BUTTON_TEXT.SAVE}</Button>
          </Tooltip>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="text" onClick={() => navigate('/admin/scorm-courses')}>{BUTTON_TEXT.BACK}</Button>
          </Tooltip>
        </Box>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <Box sx={{ width: '100%', bgcolor: 'background.paper', pl: 2 }}>
          <FormView
            title="Scorm details"
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={AccountStatusForm}
            onChangeValues={(obj: { id: string, value: any }) => handleFormResponse(obj)}
          />
        </Box>
        <ScormCourses
          scormFormLearning={scormFormLearning}
          onChangeValues={(obj: IFormResponse) => handleFormResponse(obj)}
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>

  )
}
