/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Box, Typography } from '@mui/material/';
import { IState } from 'redux/interfaces';
import { useGetUserStatsQuery } from 'services/dataApi';
import { useSelector } from 'react-redux';
import LoadingIcon from 'components/LoadingIcon';
import { splitMetrics } from './shared';
import MetricsCard from './MetricsCard';

export default function Metrics() {
  const userId = useSelector((state: IState) => state.userProfile.userId);
  const {
    isLoading, isFetching, isError, data,
  } = useGetUserStatsQuery({ userId });
  if (isLoading || isFetching) {
    return <LoadingIcon />;
  }
  if (isError) {
    return <Typography>Error loading User Stats</Typography>
  }
  const dataSet = JSON.stringify(data);
  const dataMetrics = (splitMetrics(JSON.parse(dataSet)));
  return (
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: '10px',
      gridAutoRows: '150px',
    }}
    >
      { React.Children.toArray(dataMetrics?.map((metric) => <MetricsCard {...metric} />))}
    </Box>
  );
}
