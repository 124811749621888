/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { Box, Button, Tooltip } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { IFormValue } from 'utils/interfaces';
import { IQuestion, IState } from 'redux/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import QuestionForm from 'modules/AdminTestLibraries/Questions/Form';
import ViewFormLayout from 'components/ViewFormLayout';
import useInternalStorage from 'hooks/useInternalStorage';

export default function New() {
  const navigate = useNavigate();
  const location = useLocation();
  const hash = location.hash.substring(1);
  const goBackTo = useSelector((state: IState) => state.userProfile.goBackTo);

  const [, addQuestionPoolValue] = useInternalStorage<any[]>('questionPool', []);
  const [, addQuestionListValue] = useInternalStorage<any[]>(hash, []);

  const [formValue, setFormValue] = useState<IFormValue[]>([]);

  const handleFormResponse = (obj: { id: string, value: any }) => {
    setFormValue((_values) => {
      const existingIndex = _values?.findIndex((item) => item.id === obj.id);
      return existingIndex !== -1
        ? _values?.map((item, index) => (index === existingIndex ? obj : item))
        : [..._values, obj];
    })
  };

  const handleSave = () => {
    const question = formValue?.reduce((result, item) => {
      result[item.id] = item.value;
      return result;
    }, {}) as IQuestion;
    const id = Math.floor(Math.random() * 999999) + 1;
    addQuestionPoolValue(id, question)
    if (hash) {
      addQuestionListValue(id, question)
    }
    enqueueSnackbar('Question Saved Successfully', { variant: 'success' });
    navigate(goBackTo || `/questions/detail/${id}`)
  };
  return (
    <ViewFormLayout testId="new-test-library">
      <ViewFormLayout.ActionBar>
        <Box>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
            <Button variant="contained" onClick={handleSave}>{BUTTON_TEXT.SAVE}</Button>
          </Tooltip>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="text" onClick={() => navigate('/questions')}>{BUTTON_TEXT.BACK}</Button>
          </Tooltip>
        </Box>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <QuestionForm
          onCallback={(obj: IFormValue) => handleFormResponse(obj)}
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
