/* eslint-disable max-len */
import {
  FilterProps, LabelValues, TRows, filterApplied,
} from './interface';

export const filterRows = (rows: TRows[], searchText: string, fields: string[]): any[] => {
  const matchesSearch = (row: TRows) => fields.some((field) => {
    const value = row[field];
    if (typeof value === 'string' || typeof value === 'number') {
      return value.toString().toLowerCase().includes(searchText.toLowerCase());
    }
    return false;
  });

  return rows.reduce<TRows[]>((filtered, row) => {
    if (matchesSearch(row)) {
      filtered.push(row);
    } else if (row.isCollapsable) {
      const filteredDetails = filterRows(row.details, searchText, fields);
      if (filteredDetails.length > 0) {
        filtered.push({ ...row, details: filteredDetails });
      }
    }
    return filtered;
  }, []);
};

export const getUniqueValuesForFields = (rows: TRows[], fields: FilterProps[]): LabelValues[] => {
  const valuesMap = new Map<string, Set<string>>();

  const extractValues = (rows: TRows[]) => {
    rows.forEach((row) => {
      fields.forEach((field) => {
        if (!row.isCollapsable) {
          if (row[field?.key]) {
            if (!valuesMap.has(field?.key)) {
              valuesMap.set(field?.key, new Set());
            }
          valuesMap.get(field?.key)!.add(row[field.key] as string);
          }
        }
      });
      if (row.details && row.details.length > 0) {
        extractValues(row.details);
      }
    });
  };

  extractValues(rows);

  return fields.map((field) => ({
    key: field.key,
    label: field.label,
    values: Array.from(valuesMap.get(field.key) || []),
  }));
};

interface filterProps{
  label: string; key: string;
}
export function extractParentGroups(rows: TRows[], filterByParentGroup: filterProps): LabelValues {
  const uniqueValues = new Set<string>();
  const { key, label } = filterByParentGroup;
  rows.forEach((row) => {
    if (row.details.length > 0 && row[key.replace('Group', '')]) {
      uniqueValues.add(String(row[key.replace('Group', '')]));
    }
  });

  return {
    label, key, isGroup: true, values: Array.from(uniqueValues),
  };
}

export function updateFilterValues(
  filters: filterApplied[],
  filtro: LabelValues,
  newValues: string[],
): filterApplied[] {
  const updatedFilters: filterApplied[] = [];
  let found = false;

  filters.forEach((filter) => {
    if (filter.filterId === filtro?.key) {
      found = true;
      if (newValues.length > 0) {
        updatedFilters.push({ ...filter, filterValue: newValues });
      }
    } else {
      updatedFilters.push(filter);
    }
  });

  if (!found && newValues.length > 0) {
    updatedFilters.push({
      filterId: filtro.key, filterIsGroup: filtro?.isGroup, filterLabel: filtro?.label, filterValue: newValues,
    });
  }

  return updatedFilters;
}

export function removeFilterValues(
  filters: filterApplied[],
  id: string,
  toRemove: string[],
): filterApplied[] {
  return filters.reduce((acc, filter) => {
    if (filter.filterId === id) {
      const updatedFilterValue = filter.filterValue
        .filter((value: string) => !toRemove.includes(value));

      if (updatedFilterValue.length > 0) {
        acc.push({ ...filter, filterValue: updatedFilterValue });
      }
    } else {
      acc.push(filter);
    }
    return acc;
  }, [] as filterApplied[]);
}
