/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  Stack, Alert, Grid, Typography, Button,
  Tooltip,
} from '@mui/material'
import { useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import { formatDateTime } from 'utils/helpers';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT, COURSE_TYPES_ENUM } from 'utils/constants';
import { useEnrollCoursesMutation } from 'services/dataApi';
import { EnrollCoursesRequest, EnrollCoursesResponse } from 'services/interfaces';
import { IInstructor, LearningObject } from '../shared'
import AuthorizerList from '../SharedComponents/AuthorizerList';

type GridContainerProps = {
  xs: number;
  title: string;
  children: React.ReactNode;
  height?: string;
};

function EnrolledSelfDetail(
  { enrolledSelfSelected } : {enrolledSelfSelected: LearningObject},
) {
  const [enrollSelfService, { isLoading: isLoadingEnroll }] = useEnrollCoursesMutation();
  const userId = useSelector((state: IState) => state.userProfile.userId);
  const supervisorsList = useSelector((state: IState) => state.userProfile?.user?.supervisors || []);
  const ilt = enrolledSelfSelected.learningObjectTypeId === COURSE_TYPES_ENUM.CLASSROOM;
  const online = enrolledSelfSelected.learningObjectTypeId === COURSE_TYPES_ENUM.ONLINE;
  const [enrolledResponse, setEnrolledResponse] = useState<EnrollCoursesResponse[]>([]);

  const onEnrollSelf = async (_selected: LearningObject[]) => {
    if (!_selected.length) {
      return;
    }

    const payloadList: EnrollCoursesRequest[] = _selected?.map((course : LearningObject) => ({
      userId,
      learningObjectInstanceId: String(course.learningObjectInstanceId),
      learningObjectId: String(course?.learningObjectId),
      attendees: String(userId),
      status: '2',
      isStartEnrollment: true,
      isProcessEnrollment: false,
    }))
    try {
      const response = await enrollSelfService(payloadList).unwrap();
      setEnrolledResponse(response)
    } catch (error) {
      console.log({ error })
    }
  };

  const getAvailableInstructor = (instructorSumary: string | null) => {
    const arrayInstructor: IInstructor[] = [];
    if ((instructorSumary || '').trim().length > 0) {
      const instructors = instructorSumary?.split(',');
      instructors?.forEach((element) => {
        arrayInstructor.push({
          name: element?.trim(),
        });
      });
    }
    return arrayInstructor;
  };

  function GridContainer({
    xs, title, children, height = 'auto',
  }: GridContainerProps) {
    return (
      <>
        <Grid
          item
          xs={xs}
          p={1}
          bgcolor={(theme) => theme.palette.grey[100]}
          className="center-vertical"
          sx={{ justifyContent: 'right', borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`, height }}
        >
          <Typography>{title}</Typography>
        </Grid>
        <Grid item xs={12 - xs} p={1} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}` }} className="center-vertical">
          {children}
        </Grid>
      </>
    )
  }

  function ErrorCard({ data }: {data: EnrollCoursesResponse}) {
    if (data?.enrollmentErrors) {
      const error = data?.enrollmentErrors.join('; ');
      return <Alert severity="error">{error}</Alert>
    }
    return <Alert severity="error">An errolled process had an error, check it!</Alert>
  }
  const enrrolledError = enrolledResponse[0];

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      {enrrolledError && !enrrolledError?.processSuccess && <ErrorCard data={enrrolledError} />}
      {!enrolledResponse.length && (<Alert severity="info">Please click &apos;Enroll&apos; button for enrolling in this learning activity</Alert>)}
      {enrrolledError?.processSuccess && (<Alert severity="success">{enrrolledError?.processDetailDescription}</Alert>)}
      <Grid container>
        <GridContainer xs={2} title="Course Name:">
          <Typography>
            {enrolledSelfSelected?.learningObjectName}
          </Typography>
        </GridContainer>
        <GridContainer xs={2} title="Description:">
          <Typography>
            {enrolledSelfSelected?.learningObjectDescription}
          </Typography>
        </GridContainer>
        <Grid item xs={6}>
          <Grid container>
            <GridContainer xs={4} title="Vendor:">
              <Typography>
                {enrolledSelfSelected?.vendorName}
              </Typography>
            </GridContainer>
            <GridContainer xs={4} title="Authorizer:">
              {supervisorsList?.length > 0 ? (
                <AuthorizerList authorizers={supervisorsList} />

              ) : <Typography variant="subtitle1">No Authorizers</Typography>}
            </GridContainer>
            <GridContainer xs={4} title="Payment mode:">
              <Typography>
                TBD
              </Typography>
            </GridContainer>
            <GridContainer xs={4} title="Due Date:">
              <Typography>
                TBD
              </Typography>
            </GridContainer>
            <GridContainer xs={4} title="List Price:">
              <Typography>
                {enrolledSelfSelected.listPrice ? `$ ${enrolledSelfSelected.listPrice}` : 'Free'}
              </Typography>
            </GridContainer>
            <GridContainer xs={4} title="Promo Price:">
              <Typography>
                {enrolledSelfSelected?.promoPrice}
              </Typography>
            </GridContainer>
            <GridContainer xs={4} title="Credit Units:">
              <Typography>
                {enrolledSelfSelected?.creditUnits}
              </Typography>
            </GridContainer>
            <GridContainer xs={4} title="Credit hours:">
              <Typography>
                {enrolledSelfSelected?.creditHours}
              </Typography>
            </GridContainer>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <GridContainer xs={4} title="Start Date:">
              <Typography>
                {enrolledSelfSelected?.startDateTime ? formatDateTime(enrolledSelfSelected?.startDateTime) : '-'}
              </Typography>
            </GridContainer>
            <GridContainer xs={4} title="End date:">
              <Typography>
                {enrolledSelfSelected?.endDateTime ? formatDateTime(enrolledSelfSelected?.endDateTime) : '-'}
              </Typography>
            </GridContainer>
            <GridContainer xs={4} title="Duration (in hours):">
              <Typography>
                {(online || ilt)
                  ? `${enrolledSelfSelected.duration || 0} hours`
                  : `${enrolledSelfSelected.numberOfDays || 0} days`}
              </Typography>
            </GridContainer>
            <GridContainer xs={4} title="Notes:" height="100px">
              <Typography>
                {enrolledSelfSelected?.publicDescription}
              </Typography>
            </GridContainer>
            <GridContainer xs={4} title="Location:">
              <Typography>
                {enrolledSelfSelected.fullFacilityName?.replaceAll(`${enrolledSelfSelected.facilityName || ''} - `, '') || ''}
              </Typography>
            </GridContainer>
            <GridContainer xs={4} title="Direction:" height="50px">
              <Typography>
                {enrolledSelfSelected.facilityDirection || ''}
              </Typography>
            </GridContainer>
          </Grid>
        </Grid>
        <Grid
          container
        >
          <GridContainer xs={2} title="Acreditation:" height="50px">
            <Typography>
              TBD
            </Typography>
          </GridContainer>
          <GridContainer xs={2} title="Outline:" height="50px">
            <Typography>
              TBD
            </Typography>
          </GridContainer>
        </Grid>
      </Grid>
      <Grid
        container
      >
        <Grid
          item
          bgcolor={(theme) => theme.palette.grey[100]}
          className="center-vertical"
          xs={12}
          p={1}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}` }}
        >
          Available Dates
        </Grid>
        <Grid item xs={4} p={1} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}` }} className="center-vertical">
          <div>
            <Typography><strong>Start Date</strong></Typography>
            <Typography>{enrolledSelfSelected?.startDateTime ? formatDateTime(enrolledSelfSelected?.startDateTime) : '-'}</Typography>
          </div>
        </Grid>
        <Grid item xs={4} p={1} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}` }} className="center-vertical">
          <div>
            <Typography><strong>End Date</strong></Typography>
            <Typography>{enrolledSelfSelected?.endDateTime ? formatDateTime(enrolledSelfSelected?.endDateTime) : '-'}</Typography>
          </div>
        </Grid>
        <Grid item xs={4} p={1} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}` }} className="center-vertical">
          <div>
            <Typography><strong>Time Zone</strong></Typography>
            <Typography>{enrolledSelfSelected?.timeZone || '-'}</Typography>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
      >
        <Grid
          item
          bgcolor={(theme) => theme.palette.grey[100]}
          className="center-vertical"
          xs={12}
          p={1}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}` }}
        >
          Available Instructors
        </Grid>

        {getAvailableInstructor(enrolledSelfSelected?.instructorSummary)?.map((instructor) => (
          <Grid key={instructor?.name.replaceAll(' ', '')} item xs={12} p={1} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}` }} className="center-vertical">
            <Typography>{instructor?.name}</Typography>
          </Grid>
        ))}
        {getAvailableInstructor(enrolledSelfSelected?.instructorSummary)?.length === 0 && (
          <Grid item xs={12} p={1} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}` }} className="center-vertical">
            <Typography>No Instructors</Typography>
            <br />
          </Grid>
        )}
      </Grid>
      <Grid
        container
      >
        <Grid
          item
          bgcolor={(theme) => theme.palette.grey[100]}
          className="center-vertical"
          xs={12}
          p={1}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}` }}
        >
          Attachment
        </Grid>
        <Grid item xs={12} p={1} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}` }} className="center-vertical">
          {enrolledSelfSelected?.attachmentName}
          {!enrolledSelfSelected?.attachmentName && <Typography>No Attachments</Typography>}
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
      >
        <Tooltip title={isLoadingEnroll ? ACTION_ITEM_TOOLTIP_TEXT.ENROLLING : ACTION_ITEM_TOOLTIP_TEXT.ENROLL}>
          <Button
            fullWidth={false}
            variant="contained"
            disabled={isLoadingEnroll}
            onClick={() => onEnrollSelf([enrolledSelfSelected])}
          >
            {isLoadingEnroll ? BUTTON_TEXT.ENROLLING : BUTTON_TEXT.ENROLL}
          </Button>
        </Tooltip>

      </Grid>

    </Stack>
  )
}

export default EnrolledSelfDetail
