/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  CourseDetails,
  GetAllCoursesByUserIdResponse,
  UserGroup,
  UserStatsResponse,
} from 'services/interfaces';
/* eslint-disable no-restricted-syntax */
import { filterApplied, TRows } from 'components/CustomTable/interface';
import {
  ADMIN_USER_IDS,
  COURSE_STATUS_BY_DATE,
  COURSE_STATUS_BY_DATE_LABEL,
  RESOURCE_PATTERNS,
  SCORM_WBT_TYPE_ID,
} from './constants';
import { ParentGroup } from './interfaces';

// eslint-disable-next-line import/prefer-default-export
export const groupedDataByID = (arrayList: any[], groupBy: string): any[] => {
  const group = arrayList?.reduce((acc, cur) => {
    if (!acc[cur[groupBy]]) {
      acc[cur[groupBy]] = [];
    }
    acc[cur[groupBy]].push(cur);
    return acc;
  }, {});
  return Object.values(group)?.map((x: any) => x);
};

export const generateRandomKey = (...items: any) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  `${items.join().replaceAll(',', '-').replaceAll(' ', '')}-${Math.random()
    .toString()
    .replaceAll('.', '')}`;

export function isStringNotEmpty(str: string | null) {
  return str !== null && str.trim() !== '';
}

export function isStringEmptyOrInvalid(str: any) {
  return str === null || str?.trim() === '' || str?.trim() === 'null';
}

export type TObject = {
  [key: string]: string | null,
};

export function removeNullAndEmptyValues(obj: TObject) {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null && obj[key] !== '') {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export function formatDate(inputDate: string) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export function formatDateTime(inputDateTime: string) {
  const date = new Date(inputDateTime);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${month}/${day}/${year} ${hours}:${minutes}`;
}

export function validateArray(arrayList: any[] | any) {
  if (Array.isArray(arrayList)) return arrayList;
  return typeof arrayList === 'object' ? [arrayList] : [];
}

export type LearningUserStats = {
  totalOfMyAssignments: number,
  totalOfMyCertifications: number,
  totalOfMyCourses: number,
};

// prettier-ignore
export function hasUserAnyLearningElement(
  userStatsArray: UserStatsResponse,
): boolean {
  if (!userStatsArray) return false;
  const userStats = userStatsArray
  if (
    userStats.totalOfMyAssignments === 0
    && userStats.totalOfMyCertifications === 0
    && userStats.totalOfMyCourses === 0
  ) {
    return false;
  }

  return true;
}

export const formatDateToString = (date: Date): string => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;

  return `${year}/${formattedMonth}/${formattedDay}`;
};

export function getRequirmentLabel(dueDate: string): 'Mandatory' | 'Flexible' {
  if (!dueDate) return 'Flexible';
  return 'Mandatory';
}

export function categorizeByDueDate(dueDate: string | null): COURSE_STATUS_BY_DATE {
  if (!dueDate) {
    return COURSE_STATUS_BY_DATE_LABEL.Flexible;
  }

  const today = new Date();
  const oneDay = 24 * 60 * 60 * 1000;

  const dueDateObj = new Date(dueDate);
  const diffDays = (dueDateObj.getTime() - today.getTime()) / oneDay;

  if (diffDays < 0) {
    return COURSE_STATUS_BY_DATE_LABEL.Overdue;
  }
  if (diffDays <= 7) {
    return COURSE_STATUS_BY_DATE_LABEL.Duesoon;
  }
  if (diffDays <= 30) {
    return COURSE_STATUS_BY_DATE_LABEL.Upcoming;
  }
  return COURSE_STATUS_BY_DATE_LABEL.Future;
}

export function removeDuplicatesById(array: any, removeBy: string) {
  const uniqueArray = [];
  const seenValues = new Set();

  for (const obj of array) {
    const key = obj[removeBy];

    if (!seenValues.has(key)) {
      seenValues.add(key);
      uniqueArray.push({ ...obj, [removeBy]: key });
    }
  }

  return uniqueArray;
}

export function isUserAnAdmin(groups: UserGroup[]) {
  if (!groups) return false;
  const admin = groups.find((group) => {
    if (group.groupId && group.userGroupActive) {
      return ADMIN_USER_IDS.includes(group.groupId) && group.userGroupActive;
    }
    return false;
  });

  return !!admin;
}

export function sortingArrayBy({
  sortDirection,
  sortBy,
  arrayToSort,
}: {
  sortDirection: 'asc' | 'desc';
  sortBy: string;
  arrayToSort: any[];
}): any[] {
  const DESCENDING = 'desc';

  const getValue = (item: any): string | number | null => {
    const value = item[sortBy];

    if (value == null || value === '') {
      return null;
    }

    // Check if the value is a valid date
    if (!isNaN(Date.parse(value as string))) {
      return new Date(value as string).getTime();
    }

    // Check if the value is a number
    if (typeof value === 'number') {
      return value;
    }

    // Default to string comparison
    return String(value).toUpperCase();
  };

  const sortedArray = arrayToSort
    .slice()
    .filter((item) => getValue(item) !== null)
    .sort((a, b) => {
      const fieldA = getValue(a);
      const fieldB = getValue(b);

      let comparison = 0;

      if (fieldA! > fieldB!) {
        comparison = 1;
      } else if (fieldA! < fieldB!) {
        comparison = -1;
      }
      return sortDirection === DESCENDING ? comparison * -1 : comparison;
    });

  const nullOrEmptyArray = arrayToSort.filter((item) => getValue(item) === null);

  const sortedAndConcatenatedArray = [...sortedArray, ...nullOrEmptyArray];

  // Sort the details array for each Course
  return sortedAndConcatenatedArray.map((course) => ({
    ...course,
    details: sortingArrayBy({
      sortDirection,
      sortBy,
      arrayToSort: course.details,
    }),
  }));
}

export function isEven(num: number) {
  return num % 2 === 0;
}

interface IArray {
  id: number | string;
  [key: string]: any;
}
export const addOrReplace = (arreglo: IArray[], nuevoObjeto: IArray) => {
  const index = arreglo.findIndex(
    (objeto: IArray) => objeto.id === nuevoObjeto.id,
  );
  if (index !== -1) {
    // eslint-disable-next-line no-param-reassign
    arreglo[index] = nuevoObjeto; // Reemplaza el objeto existente
  } else {
    arreglo.push(nuevoObjeto);
  }
  return arreglo;
};
export const addOrReplaceByName = (arreglo: IArray[], nuevoObjeto: IArray) => {
  const index = arreglo.findIndex(
    (objeto: IArray) => objeto.name === nuevoObjeto.name,
  );
  if (index !== -1) {
    // eslint-disable-next-line no-param-reassign
    arreglo[index] = nuevoObjeto; // Reemplaza el objeto existente
  } else {
    arreglo.push(nuevoObjeto);
  }
  return arreglo;
};

export const removeQuestionLibrary = (): string[] => {
  const keysWithNumericId: string[] = [];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i) || '';
    if (parseFloat(key)) {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error('Error parsing item from localStorage', key, error);
      }
    }
  }

  return keysWithNumericId;
};

export const getRandomQuestionLibrary = (): string => {
  const keysWithNumericId: string[] = [];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i) || '';
    if (parseFloat(key)) {
      try {
        keysWithNumericId.push(key);
      } catch (error) {
        console.error('Error parsing item from localStorage', key, error);
      }
    }
  }

  return keysWithNumericId[
    Math.floor(Math.random() * keysWithNumericId.length)
  ];
};

export const isAVideo = (source: string) => RESOURCE_PATTERNS.vimeoPattern.test(source)
  || RESOURCE_PATTERNS.youtubePattern.test(source)
  || RESOURCE_PATTERNS.mp4Pattern.test(source)
  || RESOURCE_PATTERNS.mpeg4Pattern.test(source);

export const isAPDF = (source: string) => RESOURCE_PATTERNS.pdfPattern.test(source);

export const isForDownload = (source: string) => !(isAVideo(source) || isAPDF(source));

export const isScormCourse = (id: number | null) => SCORM_WBT_TYPE_ID === id;
export function capitalizeFromLowerCamelCase(text: string) {
  return text
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function filterByLearningProcessStatus(
  data: GetAllCoursesByUserIdResponse,
  statusArray: string[],
): GetAllCoursesByUserIdResponse {
  return (
    data.reduce <
    GetAllCoursesByUserIdResponse >(
      (acc, item) => {
      // Check if item itself matches the status
        const matchesStatus = statusArray.includes(
          item?.learningProcessStatusTypeName ?? '',
        );

        // If item is collapsable, filter its LearningDetails recursively
        let filteredLearningDetails: CourseDetails[] = [];
        if (
          item.isCollapsable
        && item.learningDetails
        && item.learningDetails?.length > 0
        ) {
          filteredLearningDetails = filterByLearningProcessStatus(
            item.learningDetails,
            statusArray,
          );
        }

        if (
          matchesStatus
        || (filteredLearningDetails && filteredLearningDetails.length > 0)
        ) {
          acc.push({
            ...item,
            learningDetails:
            filteredLearningDetails.length > 0 ? filteredLearningDetails : [],
          });
        }
        return acc;
      },
      [],
    )
  );
}

function parseDate(dateString: string): Date {
  const [month, day, year] = dateString.split('/').map(Number);
  return new Date(year, month - 1, day);
}

export function filterCoursesByDate(courses:any, selectedDate: string) {
  try {
    const selected = parseDate(selectedDate);

    const filtered = courses.filter((course: any) => {
      const courseStartDate = parseDate(course.startDate);
      const courseEndDate = course.endDate ? parseDate(course.endDate) : null;
      const appliedStartDateRule = selected.getTime() >= courseStartDate.getTime()
      // eslint-disable-next-line max-len
      const appliedEndDateRule = (courseEndDate && (selected.getTime() <= courseEndDate?.getTime()));

      // eslint-disable-next-line max-len
      const isCourseValid = appliedEndDateRule ? appliedStartDateRule && appliedEndDateRule : appliedStartDateRule;
      // Recursively filter details
      const filteredDetails = filterCoursesByDate(course.details || [], String(selected));

      return isCourseValid || filteredDetails.length > 0;
    });
    return filtered
  } catch (error) {
    console.log({ error })
  }
  return courses
}

export function checkIfKeyExistsInObjects(array: any[], key: string) {
  // eslint-disable-next-line no-prototype-builtins
  return array?.every((obj) => obj?.hasOwnProperty(key));
}

export function filterRowsByField(filteredRows: any[], filterByParentGroup:ParentGroup | null, filter: filterApplied) {
  let filterArray = filteredRows;
  const field = filter.filterId;
  const fieldIsGroup = filter?.filterIsGroup;
  const fieldVals = filter.filterValue;
  if (checkIfKeyExistsInObjects(filteredRows, field)) {
    if (filterByParentGroup?.key && fieldIsGroup) {
      filterArray = filteredRows.filter((row) => fieldVals.some((value) => String(row[filterByParentGroup.key?.replace('Group', '')]).includes(value)));
    }

    if (!fieldIsGroup) {
      filterArray = filteredRows.map((row) => {
        if (row.isCollapsable && row.details.length > 0) {
          const filteredDetails = row.details.filter((detail: TRows) => fieldVals.some((value) => String(detail[field]).includes(value)));
          return { ...row, details: filteredDetails };
        } if (fieldVals.some((value) => String(row[field]).includes(value))) {
          return row;
        }

        return { ...row, details: [] };
      }).filter((row) => (row.isCollapsable ? row.details.length > 0 : fieldVals.some((value) => String(row[field]).includes(value))));
    }
  }
  return filterArray;
}

export function removeDuplicatesFromArray(dataArray: GetAllCoursesByUserIdResponse): GetAllCoursesByUserIdResponse {
  // create just one array
  const mergedArray = dataArray.flatMap((item) => {
    if (item?.isCollapsable) {
      return item.learningDetails;
    }
    return item;
  });

  // compare and delete duplicate without recursive
  const uniqueArray = Array.from(new Map(
    mergedArray.map((obj) => [obj.learningSessionId, obj]),
  ).values());
  return uniqueArray;
}
