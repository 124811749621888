/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useState } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import TextFieldStyled from 'components/TextFieldStyled';
import SelectStyled from 'components/SelectStyled';
import DateInput from 'components/DatePickerStyled';
import { removeDuplicatesById, validateArray } from 'utils/helpers';
import MultiSelectAll, { Option } from 'components/Multiselect';
import { selection } from 'components/Multiselect/interfaces';

export enum CustomInputType {
  Select = 'select',
  MultiSelect = 'multiSelect',
  Input = 'input',
  Date = 'date',
  Password = 'password',
  CheckBox = 'checkbox',
  File = 'file',
  Radio = 'radio',
  TextArea = 'textArea'
}

type customInput = 'select' | 'input' | 'checkbox' | 'date' | 'password' | 'multiSelect' | 'file' | 'radio' | 'textArea';
type columns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export interface customSelectOption {
  // eslint-disable-next-line react/no-unused-prop-types
  key: number | string;
  // eslint-disable-next-line react/no-unused-prop-types
  value: string | boolean;
}

export interface IFormItem {
  editable: boolean;
  mandatory: boolean;
  type: customInput;
  options?: customSelectOption[];
  fieldValue: string | number | null | undefined | Option[];
  fieldId: string;
  label: string;
  titleSize: columns;
}
export interface IFormView {
  title?: string;
  style: { [key: string]: string | number; };
  fields: IFormItem[];
  onChangeValues: Function;
}

function TextFieldStyledInstance(props: any) {
  return (
    <TextFieldStyled
      sx={{
        backgroundColor: 'white',
        width: '100%',
        borderRadius: 'none',
        paddingRight: '5px',
      }}
      InputLabelProps={{ shrink: false }}
      variant="standard"
      size="small"
      {...props}
    />
  )
}
function CustomInputComponent(
  {
    field,
    onChangeValues,
  }:
    {
      field: IFormItem,
      onChangeValues: Function
    },
) {
  const [multiSelectState, setMultiSelectState] = useState<selection[] | []>(validateArray(field.fieldValue || []));
  const handleMultiSelectChange = (result: selection[], id: any) => {
    let currentValue: selection[] = [];
    setMultiSelectState((current: selection[]) => {
      currentValue = removeDuplicatesById([...current, ...result], 'value')
      return currentValue;
    });
    onChangeValues({ id, value: currentValue })
  };

  if (field?.type === CustomInputType.Input) {
    return (
      <TextFieldStyledInstance
        id={field?.fieldId}
        defaultValue={field?.fieldValue}
        required={field?.mandatory}
        placeholder={field?.label}
        disabled={!field?.editable}
        onChange={(e: any) => onChangeValues({ id: e?.target.id, value: e?.target?.value })}
        type="text"
      />
    )
  }
  if (field?.type === CustomInputType.TextArea) {
    return (
      <TextFieldStyledInstance
        id={field?.fieldId}
        multiline
        defaultValue={field?.fieldValue}
        required={field?.mandatory}
        placeholder={field?.label}
        disabled={!field?.editable}
        onChange={(e: any) => onChangeValues({ id: e?.target.id, value: e?.target?.value })}
        type="text"
      />
    )
  }
  if (field?.type === CustomInputType.File) {
    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e?.target?.files && e.target.files.length > 0) {
        const file = e.target.files[0]; // The selected file
        onChangeValues({ id: e?.target.id, value: file });
      }
    };
    return (
      <TextFieldStyledInstance
        id={field?.fieldId}
        defaultValue={field?.fieldValue}
        required={field?.mandatory}
        placeholder={field?.label}
        disabled={!field?.editable}
        onChange={handleFileChange}
        accept=".zip,application/zip"
        type="file"
      />
    )
  }
  if (field?.type === CustomInputType.Select) {
    return (
      <SelectStyled
        placeholder={field?.label}
        id={field?.fieldId}
        name={field?.fieldId}
        defaultValue={field?.fieldValue || ''}
        required={field?.mandatory}
        disabled={!field?.editable}
        onChange={(e: any) => onChangeValues({ id: e?.target.name, value: e?.target?.value })}
      >
        {field?.options?.map(({ key, value }) => (
          <MenuItem key={key} value={key}>{value}</MenuItem>
        ))}
      </SelectStyled>
    )
  }
  if (field?.type === CustomInputType.CheckBox) {
    return (
      <FormControlLabel
        control={(
          <Checkbox
            defaultChecked={Boolean(field?.fieldValue) || undefined}
            disabled={!field?.editable}
            id={field?.fieldId}
            onChange={(e) => onChangeValues({ id: e?.target.id, value: e?.target?.checked })}
          />
        )}
        label=""
      />
    )
  }
  if (field?.type === CustomInputType.Date) {
    return (
      <DateInput
        defaultValue={String(field?.fieldValue)}
        onSelect={(startDate: string) => onChangeValues({ id: field?.fieldId, value: startDate })}
      />
    )
  }
  if (field?.type === CustomInputType.Password) {
    return (
      <TextFieldStyledInstance
        id={field?.fieldId}
        defaultValue={field?.fieldValue}
        required={field?.mandatory}
        placeholder={field?.label}
        disabled={!field?.editable}
        onChange={(e: any) => onChangeValues({ id: e?.target.id, value: e?.target?.value })}
        type="password"
      />
    )
  }
  if (field?.type === CustomInputType.MultiSelect) {
    const multiItems: selection[] = field?.options?.map((opt) => ({ value: String(opt?.key), label: String(opt?.value) })) || []
    return (
      <MultiSelectAll
        items={multiItems}
        selectAllLabel="Select All"
        onChange={(e: any) => handleMultiSelectChange(e, field?.fieldId)}
        value={multiSelectState}

      />
    )
  }
  if (field?.type === CustomInputType.Radio) {
    return (
      <RadioGroup
        row
        id={field?.fieldId}
        name={field?.label}
        onChange={(e: any) => onChangeValues({ id: e?.target.id, value: e?.target?.value })}
      >
        { field?.options?.map(({ key }) => <FormControlLabel disabled={!field?.editable} value={key} control={<Radio />} label={key} />)}

      </RadioGroup>
    )
  }
  return <h1>{field?.type}</h1>
}
function FormView(
  {
    style,
    title,
    fields,
    onChangeValues,
  }: IFormView,
) {
  const TOTAL_COLUMNS = 12;

  return (
    <div>
      {title && (
        <Box sx={{
          width: '100%', bgcolor: 'background.paper', pt: 2, pb: 2,
        }}
        >
          <Typography variant="h6">{title}</Typography>
        </Box>
      )}
      {React.Children.toArray(fields?.map((field: IFormItem) => {
        const label = field?.mandatory ? `${field?.label} * ` : field?.label;
        return (
          <Grid container>
            <Grid
              item
              xs={field?.titleSize}
              bgcolor={(theme) => theme.palette.grey[100]}
              className="center-vertical"
              sx={{
                ...style, justifyContent: 'right', borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
              }}
            >
              <Typography>{label}</Typography>
            </Grid>
            <Grid item xs={TOTAL_COLUMNS - (field?.titleSize || 0)} p={1} className="center-vertical">
              <CustomInputComponent key={field?.fieldId} field={field} onChangeValues={onChangeValues} />
            </Grid>
          </Grid>
        )
      }))}
    </div>
  )
}

export default FormView
