/* eslint-disable max-len */
import React, { useState } from 'react';
import { TColumns, TRows } from 'components/CustomTable/interface';
import CustomTable from 'components/CustomTable';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
import { Typography } from '@mui/material';
import { IReport } from 'redux/interfaces';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import reports from '../reports.json'

function getViewButton(id: string | number, _navigate: Function) {
  return (
    <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.MORE_INFO}>
      <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => _navigate(`${id}`)}>
        <VisibilityIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        <Typography color={(theme) => theme.palette.primary.main}>View</Typography>
      </IconButton>
    </Tooltip>
  )
}

function createData(items: IReport[], _navigate: Function): TRows[] {
  return items?.map((item: IReport) => ({
    ...item,
    view: getViewButton(item?.Id, _navigate),
    details: [],
    id: Number(item.Id),
    isCollapsable: false,
  }));
}

export default function ReportsTable() {
  const navigate = useNavigate();
  const [dataSet] = useState<TRows[] | []>(createData(reports, navigate));

  const columns: TColumns[] = [
    {
      name: 'Name',
      column: 'Name',
      width: 200,
      sortable: true,
    },
    {
      name: 'Actions',
      column: 'view',
      width: 120,
      sortable: false,
    },
  ];
  return (

    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      showSelectCheckbox={false}
    />
  )
}
