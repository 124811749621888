import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import '../App.scss';
import Widget from 'components/Widget';
import { Grid } from '@mui/material';
import InformationCourses from 'modules/InformationCourses';
import CalendarWidget from 'modules/CalendarWidget';
import UserStatus from 'modules/UserStatus';
import Metrics from 'modules/UserMetrics';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useGetUserStatsQuery } from 'services/dataApi';
import { IState } from 'redux/interfaces';
import { hasUserAnyLearningElement } from 'utils/helpers';
import LoadingIcon from 'components/LoadingIcon';
import UserHighlightPopup from 'modules/UserHighlightPopup';
import { UserStatsResponse } from 'services/interfaces';
import Welcome from './welcome';

function Dashboard() {
  const dispatch = useDispatch();
  const { userId } = useSelector((state: IState) => state.userProfile);
  const { data, isLoading } = useGetUserStatsQuery({ userId });

  const hasElementsEnrolled = hasUserAnyLearningElement(data as UserStatsResponse);

  useEffect(() => {
    dispatch(setNavigationPage('My Tasks'))
  }, []);

  if (isLoading) {
    return (
      <Layout>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          paddingTop={3}
        >
          <LoadingIcon />
        </Grid>
      </Layout>
    )
  }

  return (
    <Layout>
      {
      hasElementsEnrolled ? (
        <Grid
          container
          spacing={1}
        >
          <Widget columns={3} onMdColumns={6} height="320px" onMdOrder={1}>
            <UserStatus />
          </Widget>
          <Widget columns={5} onMdColumns={12} height="320px" onMdOrder={2}>
            <Metrics />
          </Widget>
          <Widget columns={4} onMdColumns={6} height="320px" onMdOrder={1}>
            <CalendarWidget />
          </Widget>
          <Widget columns={12} onMdOrder={3}>
            <InformationCourses />
          </Widget>

        </Grid>
      ) : <Welcome />
     }
      <UserHighlightPopup />
    </Layout>
  );
}

export default Dashboard;
