import React from 'react'
import { Card, CardContent, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { IMetricsCard } from './shared';

function MetricsCard({
  cardTestId,
  metricName,
  metricValue,
  themeColor,
  subMetric1,
  subMetric2,
  subMetric3,
  action,
}: IMetricsCard) {
  return (
    <Card data-testid={cardTestId}>
      <CardContent>
        <Typography
          sx={{
            fontSize: 13,
            textTransform: 'uppercase',
            lineHeight: '1.125em',
            minHeight: '3.25em',
          }}
          color="text.primary"
          gutterBottom
        >
          {metricName}
        </Typography>
        <Typography variant="h4" component="div" color={themeColor} onClick={() => action && action()} sx={{ cursor: action ? 'pointer' : 'auto' }}>
          {metricValue}
        </Typography>
        <Typography sx={{ fontSize: 13, mb: 1.5 }} color={grey[700]}>
          {subMetric1}
          <br />
          {subMetric2}
          <br />
          {subMetric3}
          <br />
        </Typography>
      </CardContent>
    </Card>
  );
}

export default MetricsCard;
