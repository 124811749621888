import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import NewRatingType from 'modules/NewRatingType';

function NewRatingTypePage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('New Rating Type'))
  }, []);

  return (
    <Layout>
      <NewRatingType />
    </Layout>
  );
}

export default NewRatingTypePage;
