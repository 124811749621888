import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { IState } from 'redux/interfaces';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCoursesByIdQuery } from 'services/dataApi';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from 'components/Layout';
import LoadingIcon from 'components/LoadingIcon';
import MyCourseLaunch from 'modules/InformationCourses/MyCourseLaunch';

function MyCoursesLaunchPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const { userId } = useSelector((state: IState) => state.userProfile);

  const {
    data: dataCourse,
    isLoading,
    isError,
  } = useGetCoursesByIdQuery({ userId, learningInstanceId: String(courseId) });

  useEffect(() => {
    let courseName = 'My Course';
    if (dataCourse?.courseDetails?.courseName) {
      courseName = `My Course - ${dataCourse?.courseDetails?.courseName}`;
    }
    dispatch(setNavigationPage(courseName))
  }, [dataCourse]);

  if (isError) {
    navigate('/error');
  }
  return (
    <Layout>
      <Grid
        container
        spacing={1}
      >
        <Box sx={{ width: '100%' }}>
          {isLoading ? <LoadingIcon /> : <MyCourseLaunch course={dataCourse!} goBack={() => navigate('/dashboard')} />}
        </Box>
      </Grid>
    </Layout>
  );
}

export default MyCoursesLaunchPage;
