/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Paper,
  Typography,
  styled,
} from '@mui/material';
import { PROFILE_TEXT } from 'utils/constants';
import { useScormRegisterUserMutation } from 'services/dataApi';
import LoadingBox from 'components/LoadingBox';
import { useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import { enqueueSnackbar } from 'notistack';
import ScormLauncher from './Scorm';

interface ScormLaunch {
  rusticiCourseId: string | undefined,
  scormId:number| null,
  registrationId: string | undefined,
}
export default function LaunchScormCourseView({
  // rusticiCourseId, scormId, registrationId = 'f35a28db-6edf-4bcd-bd7a-71fef2d46863',
  rusticiCourseId, scormId, registrationId,
} : ScormLaunch) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  const { userId } = useSelector((state: IState) => state.userProfile);

  const [scormRegId, setScormRegId] = useState<string | undefined | null>(registrationId)
  const [scormRegisterUser, { isLoading }] = useScormRegisterUserMutation();

  const fetchRegistrationId = async (_scormId: number) => {
    try {
      const response = await scormRegisterUser({ userId, scormId: _scormId }).unwrap()
      console.log(response)
      setScormRegId(response as unknown as string)
    } catch (err) {
      enqueueSnackbar('Failing in the SCORM instance generation!', { variant: 'error' });
    }
  };

  useEffect(() => {
    // if at first render hasn't the registrationId calls the api
    if (scormId && !registrationId && !isLoading) fetchRegistrationId(scormId)
  }, [registrationId])

  const noContent = () => (
    <Item>
      <Typography>{PROFILE_TEXT.NO_CONTENT_AVAILABLE}</Typography>
    </Item>
  )

  if (!rusticiCourseId) {
    return noContent();
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center" height="85%" maxHeight="100%" p={2} bgcolor="#fff">
      {isLoading && <LoadingBox />}
      {(!isLoading && scormRegId) && <ScormLauncher registrationId={scormRegId} />}
    </Box>
  )
}
