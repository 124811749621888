import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import Courses from 'modules/Courses/index';

function CoursesPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('Courses'))
  }, []);

  return (
    <Layout>
      <Courses />
    </Layout>
  );
}

export default CoursesPage;
