import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import Layout from 'components/Layout';
import '../App.scss';
import { Grid } from '@mui/material';
import Widget from 'components/Widget';
import YellowfinWidget from 'modules/YellowfinWidget';

function Report() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('Reports'))
  }, []);

  return (
    <Layout>
      <Grid
        container
        spacing={1}
      >

        <Widget columns={12} onMdColumns={5} height="1650px">
          <YellowfinWidget />
        </Widget>
      </Grid>
    </Layout>
  );
}

export default Report;
