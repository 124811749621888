/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { ILearningObjectFilters, IState } from 'redux/interfaces';
import { LearningObject } from 'modules/SearchLearningObject/shared';
import { TObject, removeNullAndEmptyValues } from 'utils/helpers';
import { useSearchCoursesMutation } from 'services/dataApi'
import { useSelector } from 'react-redux';
import DialogModal from 'components/DialogModal';
import FilterList from 'modules/SearchLearningObject/FilterList';
import Catalog from 'modules/Catalog';
import Widget from 'components/Widget';
import ViewFormLayout from 'components/ViewFormLayout';
import { useNavigate } from 'react-router-dom';
import AdvanceSearch from './AdvanceSearch';
import CertificationsDetails from './CertificationDetail';
import EnrolledSelfDetail from './EnrolledSelfDetail';
import LearningObjectTable from './LearningObjectTable';
import SearchBar from './SearchBar';

const DEFAULT_SEARCH_TYPE = 'Certification';
const DEFAULT_ADVANCE_FILTER = {};
const DEFAULT_SEARCH_QUERY = '';
export default function SearchLearningObject() {
  const userId = useSelector((state: IState) => state?.userProfile?.userId);
  const [searchQuery, setSearchQuery] = useState<string>(DEFAULT_SEARCH_QUERY);
  const [advanceFilter, setAdvanceFilter] = useState<ILearningObjectFilters>(DEFAULT_ADVANCE_FILTER);
  const [searchTypeOption, setSearchTypeOption] = useState<string>(DEFAULT_SEARCH_TYPE);
  const [SearchCourses, { isLoading }] = useSearchCoursesMutation();

  const [dataSet, setDataSet] = useState<LearningObject[]>([]);
  const [openCertificationsDetails, setOpenCertificationsDetails] = useState<boolean>(false);
  const [openEnrolledSelfDetail, setOpenEnrolledSelfDetail] = useState<boolean>(false);
  const [resetAll, setResetAll] = useState<boolean>(false);
  const [certificationSelected, setCertificationSelected] = useState<{ id: number; label: string }>({
    id: 0,
    label: '',
  });
  const [enrolledSelfSelected, setEnrolledSelfSelected] = useState<LearningObject>();
  const [treeIds, setTreeids] = useState<number[]>([])
  const navigate = useNavigate();

  async function fetchData(_searchTypeOption: string, _searchQuery: string, _advanceFilter: ILearningObjectFilters) {
    try {
      setDataSet([])
      const cleanAdvanceFilter = removeNullAndEmptyValues(_advanceFilter as TObject)
      const payload = await SearchCourses({
        userId,
        loSearchType: _searchTypeOption.toLowerCase(),
        learningObjectName: _searchQuery,
        learningObjectTreeIds: treeIds,
        ...cleanAdvanceFilter,
      }).unwrap();
      setDataSet(typeof payload === 'string' ? [] : payload)
    } catch (error) {
      // navigate('/error')
    }
  }
  useEffect(() => {
    fetchData(searchTypeOption, searchQuery, advanceFilter)
  }, []);

  useEffect(() => {
    if (resetAll) {
      setResetAll(false)
      setSearchTypeOption(DEFAULT_SEARCH_TYPE)
      setSearchQuery('')
      setAdvanceFilter({})
      fetchData(DEFAULT_SEARCH_TYPE, DEFAULT_SEARCH_QUERY, DEFAULT_ADVANCE_FILTER)
    }
  }, [resetAll]);

  return (
    <ViewFormLayout
      pl={0}
      pr={0}
      testId="content-library-view"
    >
      <ViewFormLayout.ActionBar>
        <SearchBar
          onSearch={() => {
            fetchData(searchTypeOption, searchQuery, advanceFilter);
          }}
          onSearchQuery={(text: string) => setSearchQuery(text)}
          searchQuery={searchQuery}
          onReset={() => setResetAll(true)}
        />
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <Grid container sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}>
          <Grid item lg={4}>
            <Box display="flex" flexDirection="column" mr={2} gap={2} sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}>

              <Widget title="Catalog" columns={12} collapse padding="0px">
                <Catalog setTreeIds={setTreeids} />
              </Widget>

              <Widget title="Search by Type" columns={12} padding="0px">
                <FilterList
                  defaultValue={DEFAULT_SEARCH_TYPE}
                  onSelected={(e: string) => setSearchTypeOption(e)}
                  resetAll={resetAll}
                />
              </Widget>
              <Widget title="Advanced Search" columns={12} padding="0px">
                <AdvanceSearch
                  onSelected={(key: string, value: string) => setAdvanceFilter((prev) => ({ ...prev, [key]: value }))}
                  resetAll={resetAll}
                />
              </Widget>
            </Box>
          </Grid>
          <Grid item lg={8}>
            <LearningObjectTable
              rows={dataSet}
              isLoading={isLoading}
              onOpenCertificationDetail={(_objectId: number, _name: string) => {
                setCertificationSelected({ id: _objectId, label: _name })
                setOpenCertificationsDetails(true)
              }}
              onOpenEnrolledSelfDetail={(learningObject : LearningObject) => {
                setEnrolledSelfSelected(learningObject)
                navigate('/content-library-detail', { state: learningObject })
              }}
            />
          </Grid>
          <DialogModal open={openCertificationsDetails} onClose={() => setOpenCertificationsDetails(false)}>
            <DialogModal.Title>
              Certification Details
            </DialogModal.Title>
            <DialogModal.Content>
              <CertificationsDetails certificationSelected={certificationSelected} />
            </DialogModal.Content>
          </DialogModal>
          <DialogModal open={openEnrolledSelfDetail} onClose={() => setOpenEnrolledSelfDetail(false)}>
            <DialogModal.Title>
              Course Details
            </DialogModal.Title>
            <DialogModal.Content>
              <EnrolledSelfDetail enrolledSelfSelected={enrolledSelfSelected as LearningObject} />
            </DialogModal.Content>
          </DialogModal>
        </Grid>
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
