import React, { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import CustomTable from 'components/CustomTable';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { IUserInList } from 'redux/interfaces';
import { ACTION_ITEM_TOOLTIP_TEXT, ACTIVE_STATUS } from 'utils/constants';
// eslint-disable-next-line import/extensions
import users from '../userList.json';

function getActionButtons(handleNewUser: ()=>void) {
  return (
    <Box display="flex">
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.EDIT_USER}>
        <IconButton onClick={handleNewUser} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <EditIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.VIEW_USER}>
        <IconButton onClick={handleNewUser} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <VisibilityIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

function createData(items: IUserInList[], handleNewUser: ()=>void): TRows[] {
  return items?.map((item: IUserInList) => ({
    ...item,
    details: [],
    id: Number(item.UserId),
    Active: item.Active ? ACTIVE_STATUS.YES : ACTIVE_STATUS.NO,
    isCollapsable: false,
    Actions: getActionButtons(handleNewUser),
  }));
}

interface UserTableProps{
  handleNewUser: () => void;
}

export default function UsersTable({ handleNewUser }: UserTableProps) {
  const [dataSet] = useState<TRows[] | []>(createData(users, handleNewUser));

  const columns: TColumns[] = [
    {
      name: 'UserId',
      column: 'UserId',
      width: 200,
      sortable: true,
    },
    {
      name: 'Name',
      column: 'Name',
      width: 120,
      sortable: true,
    },
    {
      name: 'User Name',
      column: 'UserName',
      width: 120,
      sortable: true,
    },
    {
      name: 'Employee ID',
      column: 'EmployeeId',
      width: 120,
      sortable: true,
    },
    {
      name: 'Division',
      column: 'Division',
      width: 120,
      sortable: true,
    },
    {
      name: 'Active',
      column: 'Active',
      width: 120,
      sortable: true,
    },
    {
      name: 'Job Code Description',
      column: 'JobCodeDescription',
      width: 120,
      sortable: false,
    },
    {
      name: 'JobClassDescription',
      column: 'JobClassDescription',
      width: 120,
      sortable: false,
    },
    {
      name: 'JobPositionDescription',
      column: 'JobPositionDescription',
      width: 120,
      sortable: false,
    },
    {
      name: 'Actions',
      column: 'Actions',
      width: 120,
      sortable: false,
    },
  ];
  return (

    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      showSelectCheckbox={false}
    />
  )
}
