/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Grid,
  styled,
  IconButton,
  CardActions,
  Card,
  CardContent,
  Collapse,
  Typography,
} from '@mui/material';
import useResizeScreen from 'hooks/useResizeScreen';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExpandMoreProps, IWidget, propsType } from './shared';

function Widget({
  children,
  columns,
  onMdColumns,
  title = '',
  collapse = false,
  height,
  padding = '8px',
  onMdOrder,
  ...collapseProps
}: IWidget) {
  const size = useResizeScreen();
  const [expanded, setExpanded] = useState(!collapse);

  const handleExpandClick = () => {
    setExpanded((_expanded) => !_expanded);
  };
  const gridProps: propsType = {
    [size]: size === 'md' && onMdColumns ? onMdColumns : columns,
  };

  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <Grid item {...gridProps} order={{ sm: onMdOrder || 1, md: onMdOrder || 1, lg: 1 }} xs={12} sm={12} minWidth={280}>
      <Card sx={{ width: '100%', padding, height: 'auto' }}>
        <CardActions disableSpacing>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>

          <ExpandMore
            expand={expanded}
            role="button"
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          sx={{ ...collapseProps }}
        >
          <CardContent
            sx={{ paddingTop: 0, height }}
            data-testid="widget-content"
          >
            {children}
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}

export default Widget;
