/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { dataApi } from 'services/dataApi';
import { IContentLibrary } from './interfaces'

const initialState: IContentLibrary = {
  instructors: [],
};

export const userContentLibrarySlice = createSlice({
  name: 'userContentLibrary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      dataApi.endpoints.getAllInstructors.matchFulfilled,
      (state, { payload }) => {
        state.instructors = payload
      },
    );
  },
});

export default userContentLibrarySlice.reducer;
