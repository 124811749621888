import React, { SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT, FILTERS_OPTIONS_COURSE_TYPE } from 'utils/constants';
import {
  Box, InputLabel, FormControl, NativeSelect, Button,
  Tooltip,
  Stack,
} from '@mui/material';

import ViewFormLayout from 'components/ViewFormLayout';
import CoursesTable from './CoursesTable';

export default function Courses() {
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState(FILTERS_OPTIONS_COURSE_TYPE.ALL);

  const handleFilterSelection = (selectedValue: {
        target: { value: SetStateAction<string>; };
    }) => {
    setFilterValue(selectedValue.target.value);
  };

  return (
    <ViewFormLayout testId="administrator-courses">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.NEW_COURSE}>
          <Button variant="contained" onClick={() => navigate(`detail/${1}`)}>{BUTTON_TEXT.NEW_COURSE}</Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.FilterBar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            padding: 1,
            width: '100%',
          }}
        >
          <FormControl>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Displaying
            </InputLabel>
            <NativeSelect
              value={filterValue}
              inputProps={{
                name: 'filter',
                id: 'uncontrolled-native',
              }}
              onChange={handleFilterSelection}
            >
              {React.Children.toArray(Object.entries(
                FILTERS_OPTIONS_COURSE_TYPE,
              )?.map(([, value]) => (
                <option value={value}>
                  {value}
                </option>
              )))}
            </NativeSelect>
          </FormControl>
        </Box>
      </ViewFormLayout.FilterBar>
      <ViewFormLayout.Body>
        <Stack p={1}>
          <CoursesTable />
        </Stack>
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
